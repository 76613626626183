import React, { useEffect, useState } from 'react'
import Switch from "react-switch";
import { get, post } from '../helpers/network';

const NotificationSettings = () => {
    const [data, setData] = useState({})
// const [noti, setNoti] = useState({})
    const getNotificationsActions = async() => {
        let url = await get(`user/in-app-notification-actions`)
        url = await url.json();


        setData(url?.data)
    }
    const updateNotificationsActions = async(key,val) => {
        let formData=  new FormData()
        Object?.entries(data)?.map(([key1,val1])=>{
            if (key1!==key){
                
                formData.append(key1, parseInt(val1) )
            }else{
                
                formData.append(key,parseInt(val)? 0:1)
            }
            
        })
        let url = await post(`user/in-app-notification-actions`, formData)
        getNotificationsActions()
    }
    useEffect(() => {
        getNotificationsActions()
    

    }, [])
    

    return (
        <>
            <div className='call-forwarding-wrap'>

                <div className="call-forwarding">
                    <h6>Call assigned</h6>
                    <div className="custom-control custom-switch">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={parseInt(data?.lead_assigned)}
                            onChange={() => updateNotificationsActions('lead_assigned', data?.lead_assigned )}
                        />
                    </div>
                </div>
                <div className="call-forwarding">
                    <h6>Call tagged</h6>
                    <div className="custom-control custom-switch">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={parseInt(data?.lead_tagged)}
                            onChange={() => updateNotificationsActions('lead_tagged', data?.lead_tagged )}

                        />
                    </div>
                </div>
                <div className="call-forwarding">
                    <h6>Incoming Call</h6>
                    <div className="custom-control custom-switch">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={parseInt(data?.incoming_call)}
                            onChange={() => updateNotificationsActions('incoming_call', data?.incoming_call )}

                        />
                    </div>
                </div>
                <div className="call-forwarding">
                    <h6>Upcoming Call</h6>
                    <div className="custom-control custom-switch">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={parseInt(data?.upcoming_meeting)}
                            onChange={() => updateNotificationsActions('upcoming_meeting', data?.upcoming_meeting )}

                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationSettings