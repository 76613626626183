import { getScritId } from "./globals";
import { get } from "./network";

export async function getCallerIds() {
  if (window.callerIds) {
    return window.callerIds;
  } else {
    var result = await get("get-user-numbers");
    result = await result.json();
    var cids = result.data[0];
    window.callerIds = cids;

    return cids;
  }
}

export async function getConversations(messageState) {
  var script_id = await getScritId();

  var url =
    "conversation?script_id=" +
    script_id +
    "&direction=desc&state=" +
    messageState;
  if (!messageState) {
    url = "conversation?script_id=" + script_id + "&direction=desc";
  }

  var conversations = await get(url);
  conversations = await conversations.json();
  conversations = conversations.data;

  return conversations;
}

export function getMessageBody(message) {
  switch (message.message_type) {
    case "Message":
      return message?.body || message?.note;
      break;
    case "Log":
      return message.log.title
        ? message.log.title
        : message.log.details
        ? message.log.details
        : message.log.event_name;
      break;

    default:
      return message.message_type;
      break;
  }
}
