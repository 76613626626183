import React from 'react'
import noMessage from '../images/no-message-icon.png'

const NoMessage = ({ sendSMSModal }) => {
  return (
      <div className="no-message-wrap">
          <img src={noMessage} alt=""/>
          <h5>You have no message</h5>
          <p>Your inbox is empty. Send a message to get started.</p>
          <button className="btn-main" onClick={sendSMSModal}>New Message</button>
    </div>
  )
}

export default NoMessage