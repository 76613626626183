import { useEffect, useState } from "react";
import { getScritId } from "../helpers/globals";
import { errorAlert, getApi, getCountryCode, successAlert } from "../helpers/network";

export default function Schedule({ lead, slots }) {

    const [isLoading, setIsLoading] = useState(false);
    const [teamMember, setTeamMember] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [phoneNumber, setPhoneNumber] = useState(lead.phone_number ? lead.phone_number:"+1");

    useEffect(() => {

        if (typeof slots == "object") {
            var slotDates = Object.keys(slots);
            if (slotDates.length) {
                setSelectedDate(slotDates[0]);
                setSelectedTime(slots[slotDates[0]][0]);
            }
        }
    }, []);


    const schedule = async (e) => {
        var countryCode = getCountryCode(phoneNumber);

        if(!countryCode){
            errorAlert("Phone number's country code not recognized");
            return;
        }

        setIsLoading(true);
        var script_id = await getScritId();
        

        var scheduleResult = await getApi(`request-call?scheduled_at=${selectedDate} ${selectedTime}&phone=${phoneNumber}&country_code=${countryCode}&type=SCHEDULE_CALL&time_zone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&name=${lead.first_name} ${lead.last_name}&title=ScheduledCall&pId=${script_id}`);
        scheduleResult = await scheduleResult.json();

        if (scheduleResult.errors && scheduleResult.errors.length > 0) {
            errorAlert(scheduleResult.errors[0]);
        } else {
            successAlert("Call has been scheduled successfully");
        }

        setIsLoading(false);
    }

    return (
        <>
            <h6 className="text-lg">Schedule Call (#{lead ? lead.id : ""})</h6>

            <div className="mt-4">
                <h6 className="text-lg">Select a date</h6>
                <select value={selectedDate} onChange={(e) => { setSelectedDate(e.target.value) }} className="w-full mb-4 text-gray-900 px-4 py-1" id="available_slots">
                    {slots && Object.keys(slots).map((slot, index) => (
                        <option key={index} value={slot}>{slot}</option>
                    ))}
                </select>

                <h6 className="text-lg">Select a time</h6>
                <select value={selectedTime} onChange={(e) => { setSelectedTime(e.target.value) }} className="w-full mb-4text-gray-900 px-4 py-1 text-gray-700" id="available_slot_time">
                    {slots && selectedDate && slots[selectedDate].map((time, index) => (
                        <option key={index} value={time}>{time}</option>
                    ))}
                </select>

                <h6 className="mt-4 text-lg">Customer's phone number (with country code)</h6>
                <input onChange={(e) => { setPhoneNumber(e.target.value) }} value={phoneNumber} type="text" placeholder="+10000000000" className="w-full px-2 py-1 text-gray-700" id="callback_phone_number" />

            </div>

            <div className="mt-3 mb-2">
                <button disabled={isLoading} onClick={schedule} id="callback-lead" className="btn-main mt-2 !text-lg">Proceed</button>
            </div>

        </>
    )

}