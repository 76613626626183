export default function SwipeUpModal({ component, onClosed }) {

    return (
        <>
            <div className="transfer-modal" style={{ backgroundColor: "#353e59", zIndex: 99 }} id="callback-modal">


                <svg onClick={() => { onClosed() }} id="close-callback-modal" className="w-6 h-6 float-right cursor-pointer mt-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>

                <div className="mt-3">
                    {component}
                </div>

            </div>
        </>
    );

}