import { useState } from "react";
import GoogleButton from "../components/GoogleButton";
import {
    appClientID,
    appClientSecret,
    errorAlert,
    get,
    post,
    setAccessToken
} from "../helpers/network";
import { Link, useNavigate } from "react-router-dom";
import DownModel from "../components/DownModel";
import SwipeUpModal from "../components/SwipeUpModal";
import ResetPasswordModel from "../components/ResetPasswordModel";

export default function Signup() {
    const navigate = useNavigate()
    const [email, setEmail] = useState();
    const [name, setName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const onLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        var formdata = new FormData();
        formdata.append("client_id", appClientID);
        formdata.append("client_secret", appClientSecret);
        formdata.append("grant_type", "password");
        formdata.append("email", email);
        formdata.append("name", name);

        const result = await get(`check-if-spam-email?email=${email}`);
        const resultJson = await result.json();
        if (resultJson.data.access_token) {
            setAccessToken(resultJson.data.access_token);
            // window.location.reload();
            window.location.href = "/verify-email";

        } else {
            errorAlert("Login failed");
        }
        setIsLoading(false);
    };

    return (
        <>
            <section className="login h-full flex flex-col" >
                <div className="logo">
                    <a href="#/">
                        <img src="images/logo-limecall.svg" alt="Logo LimeCall" />
                    </a>
                </div>
                <h1>Create a new account</h1>
                <form onSubmit={onLogin} id="login_form">

                    <label>
                        Name:
                        <input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            id="singup_name"
                            type="text"
                        />
                    </label>
                    <label>
                        E-mail addresss:
                        <input
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            id="singup_email"
                            type="email"
                        />
                    </label>
                

                    <button onClick={() => navigate('/signup')} type="submit" className="btn-main">
                        Sign Up
                    </button>
                </form>
            </section>
        
        </>
    );
}
