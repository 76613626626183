import { PhoneXMarkIcon, TrashIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { getScritId } from "../helpers/globals";
import { postJson, successAlert } from "../helpers/network";
import callBackIcon from "../images/callback.svg";
import schuduleIcon from "../images/schedule.svg";
import spam from '../images/spam.png'
export default function LeadListItem({
  activeTab,
  lead,
  onLeadClicked,
  onLeadTransfer,
  onCallback,
  onScheduleCall,
  refresh,
  setShowSwipeUpModal,
  setScriptId,
  setLeadData,
}) {
  const navigate = useNavigate();
  async function marksAsSpam(e) {
    e.stopPropagation();
    e.target.disabled = true;

    await postJson(
      "leads/change-status",
      JSON.stringify({
        lead_id: lead.id,
        status: "3",
      })
    );

    refresh();
  }

  async function onleadClaimed(e) {
    e.stopPropagation();
    e.target.disabled = true;

    await postJson(
      `leads/set-owner`,
      JSON.stringify({
        lead_id: lead.id,
        user_id: window.user.id,
      })
    );

    refresh();
  }

  function doLeadTransfer(e, lead) {
    e?.stopPropagation();

    onLeadTransfer(lead);
  }

  function doCallback(e) {
    e?.stopPropagation();

    onCallback(lead);
  }

  function doSchedule(e) {
    e?.stopPropagation();

    onScheduleCall(lead);
  }

  const onDelete = async (leadId) => {
    var proceed = window.confirm(
      `Are you sure? Lead ID #${leadId} will be deleted.`
    );

    if (!proceed) {
      return;
    }

    await postJson(
      `leads/delete-leads`,
      JSON.stringify({
        ids: [leadId],
      })
    );

    refresh();
  };

  const getStatus = (lead) => {
    if (+lead?.interseted === 1) {
      return "Good Fit";
    } else if (+lead?.interseted === 2) {
      return "Pending";
    } else if (+lead?.interseted === 3) {
      return "Bad Fit";
    } else {
      return "Spam";
    }
  };

  const UpdateStatus = async (e, id) => {
    if (e?.target?.value && id) {
      const postData = {
        lead_id: id,
        status: +e?.target?.value,
      };
      const result = await postJson(
        "leads/change-status",
        JSON.stringify(postData)
      );
      if (result?.status === 200) {
        refresh();
      }
    }
  };

  const getScriptIdData = async () => {
    const abc = await getScritId();
    setScriptId(abc);
    setShowSwipeUpModal(true);
    setLeadData({ phone_number: lead.phone_number });
  };

  return (
    <div onClick={onLeadClicked} className="item">
      <div className="d-flex align-items-center justify-content-between">
      <div className="company lead_name">
        {lead.contact_name ? lead.contact_name : "Unknownn"}
      </div>
      <div
            id="lead_id"
            className="list-item lead_li_id flex-row-reverse	gap-2"
          >
       
            <TrashIcon
              data-tooltip-content="Delete"
              id={"delete_lead_btn_" + lead.id}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(lead.id);
              }}
              className="h-5 text-red-500 w-5"
            />
            <span
              onClick={(e) => {
                e?.stopPropagation();
                doSchedule();
              }}
            >
              <img
                title="Schedule"
                className="image-size"
                src={schuduleIcon}
                alt="schedule"
              />
            </span>
            <span
              onClick={(e) => {
                e?.stopPropagation();
                doCallback();
              }}
            >
              <img
                title="CallBack"
                className="image-size"
                src={callBackIcon}
                alt="callback"
              />
            </span>
          <span
            onClick={(e) => {
              e?.stopPropagation();
            const aa=  window.confirm("Are you sure to mark this lead as spam and block it ?");
            console.log('aa', aa)
              if (aa){
                
                 marksAsSpam(e);
              }
            }}
          >
            <img
              title="Spam"
              className="image-size"
              src={spam}
              alt="schedule"
            />
          </span>
            {lead.phone_number ? (
              <>
                <span
                  onClick={(e) => {
                    e?.stopPropagation();
                    navigate("/dialer", {
                      state: {
                        number: lead.phone_number,
                      },
                    });
                  }}
                >
                  <svg
                    class="h-5 w-5 mr-0"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke="#fff"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    ></path>
                  </svg>
                </span>
                <span
                  onClick={(e) => {
                    e?.stopPropagation();
                    getScriptIdData();
                  }}
                >
                  <svg
                    title="Message"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 cursor-pointer text-white mr-0"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                    ></path>
                  </svg>
                </span>
              </>
            ) : null}

            {/* #{lead.id} */}
        </div>
      </div>

      <div id="phone_number" className="list-item phone phone-numbers">
        <svg
          className="leads-list-icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          ></path>
        </svg>

        <a className="text-white" href={"tel:" + lead.phone_number}>
          {lead.phone_number ? lead.phone_number : "No phone"}
        </a>
      </div>

      <div className="flex lead-nav" id={lead.id}>
        <div className="list">

          <div className="list-item">
            {lead.final_status == "completed" ? (
              <PhoneXMarkIcon className="text-green-500 h-4 w-4" />
            ) : (
              <PhoneXMarkIcon className="text-red-500 h-4 w-4" />
            )}
            {lead.final_status == "completed" ? (
              <span className="text-green-500">{lead.final_status}</span>
            ) : (
              <span className="text-red-500">
                {lead.final_status === "failed_to_connect_customer"
                  ? "Call Failed"
                  : lead.final_status}
              </span>
            )}
          </div>

          <div className="list-item">
            <svg
              className="leads-list-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              ></path>
            </svg>
            {lead.type}
          </div>

          <div id="phone_number" className="list-item phone phone-numbers">
            <svg
              class="leads-list-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              ></path>
            </svg>
            #{lead.id}
          </div>
          {/* <div id="phone_number" className="list-item phone phone-numbers">
            <svg
              className="leads-list-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              ></path>
            </svg>

            <a className="text-white" href={"tel:" + lead.phone_number}>
              {lead.phone_number ? lead.phone_number : "No phone"}
            </a>
          </div> */}

          <div className="list-item">
            <svg
              className="leads-list-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              ></path>
            </svg>

            {lead.agent ? lead.agent : "No agent"}
          </div>

          <div className="list-item">
            <svg
              className="leads-list-icon"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
              ></path>
            </svg>

            {lead.owner ? lead.owner : "No owner"}
          </div>

          <div className="list-item">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99992 1.33325C4.32394 1.33325 1.33325 4.32394 1.33325 7.99992C1.33325 11.6759 4.32394 14.6666 7.99992 14.6666C11.6759 14.6666 14.6666 11.6759 14.6666 7.99992C14.6666 4.32394 11.6759 1.33325 7.99992 1.33325ZM7.99992 2.33325C11.1355 2.33325 13.6666 4.86438 13.6666 7.99992C13.6666 11.1355 11.1355 13.6666 7.99992 13.6666C4.86438 13.6666 2.33325 11.1355 2.33325 7.99992C2.33325 4.86438 4.86438 2.33325 7.99992 2.33325ZM7.82544 3.99276C7.69295 3.99483 7.56669 4.0494 7.4744 4.14449C7.38212 4.23959 7.33135 4.36742 7.33325 4.49992V8.49992C7.33327 8.63252 7.38595 8.75969 7.47971 8.85346C7.57348 8.94722 7.70065 8.99991 7.83325 8.99992H10.4999C10.5662 9.00086 10.632 8.98862 10.6934 8.96391C10.7549 8.9392 10.8109 8.90252 10.8581 8.856C10.9053 8.80948 10.9427 8.75404 10.9683 8.69291C10.9939 8.63178 11.007 8.56618 11.007 8.49992C11.007 8.43366 10.9939 8.36805 10.9683 8.30693C10.9427 8.2458 10.9053 8.19036 10.8581 8.14384C10.8109 8.09732 10.7549 8.06064 10.6934 8.03593C10.632 8.01122 10.5662 7.99898 10.4999 7.99992H8.33325V4.49992C8.33421 4.433 8.32173 4.36656 8.29654 4.30455C8.27135 4.24255 8.23397 4.18623 8.18661 4.13893C8.13926 4.09164 8.08289 4.05433 8.02085 4.02922C7.95881 4.00411 7.89236 3.99171 7.82544 3.99276Z"
                fill="#656B82"
              ></path>
            </svg>
            {moment(lead.created_at).fromNow()}
          </div>

          <div className="list-item">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00006 1.33667C7.44453 1.33667 6.88915 1.54719 6.46816 1.96818C6.46794 1.96818 6.46773 1.96818 6.46751 1.96818L1.96816 6.46818C1.12617 7.31017 1.12617 8.68999 1.96816 9.53198L6.46816 14.032C7.31021 14.874 8.68991 14.874 9.53196 14.032C9.53218 14.032 9.5324 14.032 9.53261 14.032L14.0313 9.53198C14.8733 8.68999 14.8733 7.31017 14.0313 6.46818L9.53196 1.96818C9.11097 1.54719 8.55559 1.33667 8.00006 1.33667ZM8.00006 2.33016C8.29753 2.33016 8.59493 2.44521 8.82493 2.67521L13.3243 7.17521C13.7843 7.63522 13.7843 8.36494 13.3243 8.82495L8.82493 13.325C8.36498 13.7849 7.63514 13.7849 7.17519 13.325L2.67519 8.82495C2.21518 8.36494 2.21518 7.63522 2.67519 7.17521L7.17519 2.67521C7.4052 2.44521 7.70259 2.33016 8.00006 2.33016ZM9.45254 6.00203C9.40383 6.00669 9.35549 6.01842 9.30866 6.03784C9.12166 6.11517 9.00006 6.29775 9.00006 6.50008V7.00008H7.8334C6.8268 7.00008 6.00006 7.82682 6.00006 8.83341V10.1667C5.99913 10.233 6.01137 10.2988 6.03607 10.3603C6.06078 10.4218 6.09746 10.4777 6.14398 10.5249C6.1905 10.5721 6.24594 10.6096 6.30707 10.6351C6.3682 10.6607 6.4338 10.6739 6.50006 10.6739C6.56632 10.6739 6.63193 10.6607 6.69306 10.6351C6.75418 10.6096 6.80962 10.5721 6.85614 10.5249C6.90266 10.4777 6.93934 10.4218 6.96405 10.3603C6.98876 10.2988 7.001 10.233 7.00006 10.1667V8.83341C7.00006 8.36734 7.36732 8.00008 7.8334 8.00008H9.00006V8.50008C9.00006 8.70241 9.12199 8.88499 9.30866 8.96232C9.37066 8.98799 9.43508 9.00008 9.49941 9.00008C9.62974 9.00008 9.75791 8.9496 9.85358 8.8536L10.8536 7.8536C11.0489 7.6586 11.0489 7.34157 10.8536 7.14657L9.85358 6.14657C9.74633 6.03932 9.59865 5.98807 9.45254 6.00203Z"
                fill="#656B82"
              ></path>
            </svg>
            {lead.first_source ? lead.first_source : "No source"}
          </div>

          <div className="list-item">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.00006 1.33667C7.44453 1.33667 6.88915 1.54719 6.46816 1.96818C6.46794 1.96818 6.46773 1.96818 6.46751 1.96818L1.96816 6.46818C1.12617 7.31017 1.12617 8.68999 1.96816 9.53198L6.46816 14.032C7.31021 14.874 8.68991 14.874 9.53196 14.032C9.53218 14.032 9.5324 14.032 9.53261 14.032L14.0313 9.53198C14.8733 8.68999 14.8733 7.31017 14.0313 6.46818L9.53196 1.96818C9.11097 1.54719 8.55559 1.33667 8.00006 1.33667ZM8.00006 2.33016C8.29753 2.33016 8.59493 2.44521 8.82493 2.67521L13.3243 7.17521C13.7843 7.63522 13.7843 8.36494 13.3243 8.82495L8.82493 13.325C8.36498 13.7849 7.63514 13.7849 7.17519 13.325L2.67519 8.82495C2.21518 8.36494 2.21518 7.63522 2.67519 7.17521L7.17519 2.67521C7.4052 2.44521 7.70259 2.33016 8.00006 2.33016ZM9.45254 6.00203C9.40383 6.00669 9.35549 6.01842 9.30866 6.03784C9.12166 6.11517 9.00006 6.29775 9.00006 6.50008V7.00008H7.8334C6.8268 7.00008 6.00006 7.82682 6.00006 8.83341V10.1667C5.99913 10.233 6.01137 10.2988 6.03607 10.3603C6.06078 10.4218 6.09746 10.4777 6.14398 10.5249C6.1905 10.5721 6.24594 10.6096 6.30707 10.6351C6.3682 10.6607 6.4338 10.6739 6.50006 10.6739C6.56632 10.6739 6.63193 10.6607 6.69306 10.6351C6.75418 10.6096 6.80962 10.5721 6.85614 10.5249C6.90266 10.4777 6.93934 10.4218 6.96405 10.3603C6.98876 10.2988 7.001 10.233 7.00006 10.1667V8.83341C7.00006 8.36734 7.36732 8.00008 7.8334 8.00008H9.00006V8.50008C9.00006 8.70241 9.12199 8.88499 9.30866 8.96232C9.37066 8.98799 9.43508 9.00008 9.49941 9.00008C9.62974 9.00008 9.75791 8.9496 9.85358 8.8536L10.8536 7.8536C11.0489 7.6586 11.0489 7.34157 10.8536 7.14657L9.85358 6.14657C9.74633 6.03932 9.59865 5.98807 9.45254 6.00203Z"
                fill="#656B82"
              ></path>
            </svg>
            {getStatus(lead)}
          </div>
        </div>
      </div>

      <div className="item-links flex-start">
        {/* <button
          onClick={marksAsSpam}
          className="lead-action-btn mark-spam-btn"
          leadid="21590"
          status="2"
        ></button> */}
        {!lead.owner_id && (
          <button
            onClick={onleadClaimed}
            className="lead-action-btn claim-btn"
            href="#/"
          ></button>
        )}

        <button
          onClick={(e) => doLeadTransfer(e, lead)}
          className="lead-action-btn transfer-btn"
          href="#/"
          leadid="21590"
        ></button>

        <select
          title="status"
          className="lead-action-btn transfer-btn"
          onChange={(e) => UpdateStatus(e, lead.id)}
          onClick={(e) => e?.stopPropagation()}
          defaultValue={lead?.status?.toString()}
        >
          <option value={"1"}>Pending</option>
          <option value={"2"}>Assigned</option>
          <option value={"3"}>Closed</option>
          <option value={"34"}>Qualified</option>
        </select>
        {/* </div> */}

        {/* {lead.phone_number && <button onClick={doSchedule} className="lead-action-btn schedule-btns" data-phone="+201012392627" href="#/" leadid="21590">Schedule</button>}
                {lead.phone_number && <button onClick={doCallback} className="lead-action-btn callback-btns" data-phone="+201012392627" href="#/" leadid="21590"></button>} */}
      </div>

      <Tooltip anchorId={"delete_lead_btn_" + lead.id} />
    </div>
  );
}
