import moment from "moment";
import { useEffect, useState } from "react";
import { get, postJson } from "../helpers/network";

export default function LeadTabNotes({ lead }) {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [noteInput, setNoteInput] = useState();

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    const result = await get("leads/" + lead.id + "/note");
    const resultJson = await result.json();
    const _notes = resultJson.data;

    if (_notes && _notes.length) {
      const data = _notes.map((list, i) => {
        list.index = i;
        return list;
      });
      setNotes(data?.reverse());
    } else {
      setNotes([]);
    }
  };

  const postNote = async (e) => {
    e.preventDefault();

    setNoteInput("");
    setLoading(true);
    await postJson(
      `leads/${lead.id}/add-note`,
      JSON.stringify({
        content: noteInput,
      })
    );
    setLoading(false);

    getNotes();
  };

  const onDelete = async (index) => {
    await postJson(
      `leads/delete-notes-new`,
      JSON.stringify({
        lead_id: lead.id,
        id: index,
      })
    );
    getNotes();
  };

  return (
    <>
      <form onSubmit={postNote} id="lead_notes_form">
        <textarea
          value={noteInput}
          onChange={(e) => {
            setNoteInput(e.target.value);
          }}
          id="note"
          placeholder="Start typing to leave a note"
        >
          {noteInput}
        </textarea>
        <input
          disabled={loading}
          id="save-call-notes-btn"
          className="btn-main"
          type="submit"
          value="Save"
        />
      </form>
      <div className="leads-list" id="notes-list">
        {notes &&
          notes.map((note, i) => (
            <div key={i} className="item">
              <div className="company lead_name">
                <span>{note?.userName}</span>

                <svg
                  onClick={() => onDelete(note?.index)}
                  data-tooltip-content="Delete"
                  id="lead-delete"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-red-500 w-6 h-6 absolute right-8 -mt-2 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>
              <p className="text-white">{note.note}</p>
              <div className="flex lead-nav" id="{id}">
                <div className="list w-full">
                  <div className="list-item">
                    <svg
                      className="text-white"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99992 1.33325C4.32394 1.33325 1.33325 4.32394 1.33325 7.99992C1.33325 11.6759 4.32394 14.6666 7.99992 14.6666C11.6759 14.6666 14.6666 11.6759 14.6666 7.99992C14.6666 4.32394 11.6759 1.33325 7.99992 1.33325ZM7.99992 2.33325C11.1355 2.33325 13.6666 4.86438 13.6666 7.99992C13.6666 11.1355 11.1355 13.6666 7.99992 13.6666C4.86438 13.6666 2.33325 11.1355 2.33325 7.99992C2.33325 4.86438 4.86438 2.33325 7.99992 2.33325ZM7.82544 3.99276C7.69295 3.99483 7.56669 4.0494 7.4744 4.14449C7.38212 4.23959 7.33135 4.36742 7.33325 4.49992V8.49992C7.33327 8.63252 7.38595 8.75969 7.47971 8.85346C7.57348 8.94722 7.70065 8.99991 7.83325 8.99992H10.4999C10.5662 9.00086 10.632 8.98862 10.6934 8.96391C10.7549 8.9392 10.8109 8.90252 10.8581 8.856C10.9053 8.80948 10.9427 8.75404 10.9683 8.69291C10.9939 8.63178 11.007 8.56618 11.007 8.49992C11.007 8.43366 10.9939 8.36805 10.9683 8.30693C10.9427 8.2458 10.9053 8.19036 10.8581 8.14384C10.8109 8.09732 10.7549 8.06064 10.6934 8.03593C10.632 8.01122 10.5662 7.99898 10.4999 7.99992H8.33325V4.49992C8.33421 4.433 8.32173 4.36656 8.29654 4.30455C8.27135 4.24255 8.23397 4.18623 8.18661 4.13893C8.13926 4.09164 8.08289 4.05433 8.02085 4.02922C7.95881 4.00411 7.89236 3.99171 7.82544 3.99276Z"
                        fill="#656B82"
                      ></path>
                    </svg>

                    {note?.date &&
                      moment(note?.date).format("DD-MM-YYYY H:m A")}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
