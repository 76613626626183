import axios from "axios";
import React, { useEffect, useState } from "react";
import { get, post } from "../helpers/network";

export default function VirtualNumber() {
  const [info, setInfo] = useState([]);
  const [virtualList, setVirtualList] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [isTemMem, setIsTemMem] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [infoList, setInfoList] = useState({
    teamList: {},
    platformList: [],
  });
  const [memberList, setMemberList] = useState({});
  const [currentType, setCurrentType] = useState({
    type: "",
    index: "",
  });
  const [externalNoList, setExternalNoList] = useState({});
  const [loader, setLoader] = useState(false);

  const AssignTo = [
    {
      key: "Select",
      text: "Select",
      value: "",
    },
    {
      key: "Platform",
      text: "Platform",
      value: "platform",
    },
    {
      key: "Team",
      text: "Team",
      value: "team",
    },
    {
      key: "Voicemail",
      text: "Voicemail",
      value: "voicemail",
    },
    {
      key: "Team-members",
      text: "Team Members",
      value: "teamMembers",
    },
    {
      key: "IVR",
      text: "IVR",
      value: "ivr",
    },
    {
      key: "externalNumber",
      text: "External Number",
      value: "externalNumber",
    },
  ];

  const getTeamsData = async () => {
    setLoader(true);
    const res = await get(`team/teams?limit=20000&offset=0`);
    const resJson = await res.json();
    setInfoList({
      ...infoList,
      teamList: resJson,
    });
    setLoader(false);
  };

  const getExternalNumberList = async () => {
    setLoader(true);
    const res = await get(`external-numbers`);
    const resJson = await res.json();
    setExternalNoList(resJson);
    setLoader(false);
  };

  const getTeamMembers = async () => {
    setLoader(true);
    const res1 = await get(`team/members`);
    const resJson1 = await res1.json();
    setMemberList(resJson1);
    setLoader(false);
  };

  const handleData = () => {
    let dataList = [];
    infoList?.teamList?.data?.forEach((item) => {
      dataList.push({
        key: item.name,
        text: item.name,
        value: item.id,
      });
    });
    dataList.unshift({
      key: `SelectTeam`,
      text: `Select Team `,
      value: "",
    });

    return dataList;
  };

  const manageExternalNumbers = () => {
    let dataList = [];
    externalNoList?.data.forEach((item) => {
      dataList.push({
        key: item.phone_number,
        text: item.phone_number,
        value: item.id,
      });
    });

    dataList.unshift({
      key: `SelectExternalNumber`,
      text: `Select External No. `,
      value: "",
    });

    return dataList;
  };

  const handleTeamMembersData = () => {
    let dataList = [];
    let data = [];
    data = memberList?.data?.members;

    if (data && data?.length) {
      data.forEach((item) => {
        dataList.push({
          key: `${item.first_name}-${item.last_name}`,
          text: `${item.first_name} ${item.last_name}`,
          value: item.id,
        });
      });
      dataList.unshift({
        key: `SelectTeam`,
        text: `Select Team Mem`,
        value: "",
      });
    }
    return dataList;
  };

  useEffect(() => {
    if (virtualList.length) {
      let clone = [...virtualList];
      virtualList.forEach((item, index) => {
        if (item.type === "team") {
          clone[index].teamList = handleData();
        }
      });
      setVirtualList(clone);
    }
  }, [infoList.teamList]);

  useEffect(() => {
    if (virtualList.length) {
      let clone = [...virtualList];
      virtualList.forEach((item, index) => {
        if (item.type === "user") {
          clone[index].teamMembersList = handleTeamMembersData();
        }
      });
      setVirtualList(clone);
    }
  }, [memberList]);

  useEffect(() => {
    if (virtualList.length) {
      let clone = [...virtualList];
      virtualList.forEach((item, index) => {
        if (item.type === "number") {
          clone[index].externalNumbers = manageExternalNumbers();
        }
      });
      setVirtualList(clone);
    }
  }, [externalNoList]);

  useEffect(() => {
    if (isAdded) {
      getTeamsData();
      getExternalNumberList();
    }
  }, [isAdded]);

  useEffect(() => {
    if (isTemMem) {
      getTeamMembers();
    }
  }, [isTemMem]);

  useEffect(() => {
    getUserNumbers();
    // getExternalNumberList();
    // getWidgetCountries();
  }, []);

  useEffect(() => {
    if (callAgain) {
      getUserNumbers();
    }
  }, [callAgain]);

  const getUserNumbers = async () => {
    setLoader(true);
    let result = await get(`get-user-numbers`);
    result = await result.json();
    let ls = [];
    if (result?.data) {
      if (result?.data?.length) {
        result?.data.forEach((item) => {
          ls.push({
            data: item,
            type: item?.assigned?.type,
            teamList:
              item?.assigned?.type === "team"
                ? infoList?.teamList?.data?.length
                  ? handleData()
                  : []
                : [],
            platformList: [],
            teamMembersList:
              item?.assigned?.type === "user"
                ? Object.keys(memberList).length
                  ? handleTeamMembersData()
                  : []
                : [],
            targetNumber: "",
            externalNumbers:
              item?.assigned?.type === "number"
                ? Object.keys(externalNoList).length
                  ? manageExternalNumbers()
                  : []
                : [],
          });
        });
        setVirtualList(ls);
        setIsAdded(true);
        setIsTemMem(true);
        setCallAgain(false);
        setCurrentType({ type: "", index: "" });
      } else {
        setVirtualList([]);
        setIsAdded(true);
        setIsTemMem(true);
        setCallAgain(false);
        setCurrentType({ type: "", index: "" });
      }
      setInfo(result?.data || []);
    }
    setLoader(false);
  };

  const handleOnChange = async (e, index, id) => {
    const { value } = e.target;
    setCurrentType({
      type: value,
      index,
    });

    if (value === "team") {
      let resJson = "";
      let dataList = [];
      if (infoList?.teamList?.data?.length) {
        resJson = infoList.teamList;
      } else {
        setLoader(true);
        const res = await get(`team/teams?limit=20000&offset=0`);
        resJson = await res.json();
        setLoader(false);
      }
      resJson?.data?.forEach((item) => {
        dataList.push({
          key: item.name,
          text: item.name,
          value: item.id,
        });
      });
      dataList.unshift({
        key: `Select ${value.charAt(0).toUpperCase() + value.slice(1)}`,
        text: `Select ${value.charAt(0).toUpperCase() + value.slice(1)}`,
        value: "",
      });
      const clone = [...virtualList];
      clone[index].teamList = dataList;
      clone[index].type = value;
      setVirtualList(clone);
    }
    if (value === "platform") {
      const clone = [...virtualList];
      clone[index].type = value;
      setVirtualList(clone);
      setLoader(true);
      const res = await get(`widget/widgets`);
      const resJson = await res.json();
      setLoader(false);
      const payload = new FormData();
      payload.append("type", "widget");
      payload.append("widget_id", resJson?.data[0]?.id);
      setLoader(true);
      let result = await post(`number/${id}/assign`, payload);
      result = await result.json();
      setCallAgain(true);
      setLoader(false);
    }
    if (value === "voicemail") {
      const clone = [...virtualList];
      clone[index].type = value;
      setVirtualList(clone);

      var reqHeaders = new Headers();
      reqHeaders.append("Accept", "application/json");
      reqHeaders.append("Authorization", "Bearer " + window.apiToken);
      setLoader(true);
      axios
        .put(
          `${window.endPoint + "/api/" + "v1/"}number/${id}/voicemail-status`,
          { voicemail_status: 3 },
          { reqHeaders }
        )
        .then((res) => {
          setCallAgain(true);
          setLoader(false);
        })
        .catch(async (error) => {
          setLoader(false);
        });
    }

    if (value === "teamMembers") {
      let data = handleTeamMembersData();
      const clone = [...virtualList];
      clone[index].teamMembersList = data;
      clone[index].type = value;
      setVirtualList(clone);
    }

    if (value === "externalNumber") {
      const clone = [...virtualList];
      clone[index].type = value;
      clone[index].externalNumbers = manageExternalNumbers();
      setVirtualList(clone);
    }

    if (value === "ivr") {
      const clone = [...virtualList];
      clone[index].type = value;
      setVirtualList(clone);

      const payload = new FormData();
      payload.append("type", value);
      setLoader(true);
      let result = await post(`number/${id}/assign`, payload);
      result = await result.json();

      setCallAgain(true);
      setLoader(false);
    }
  };

  const handleTeamOnChange = async (e, id) => {
    const { value } = e.target;
    const ls = [value];

    var payload = new FormData();
    ls.forEach((list) => payload.append("team_id[]", list));
    payload.append("type", "team");
    setLoader(true);
    var result = await post(`number/${id}/assign`, payload);
    result = await result.json();
    setCallAgain(true);
    setLoader(false);
  };

  const handleTeamMembersOnChange = async (e, id) => {
    const { value } = e.target;
    const ls = [value];

    var payload = new FormData();
    ls.forEach((list) => payload.append("member_id[]", list));
    payload.append("type", "member");
    setLoader(true);
    var result = await post(`number/${id}/assign`, payload);
    result = await result.json();
    setCallAgain(true);
    setLoader(false);
  };

  const externalNoChange = async (e, id) => {
    const { value } = e.target;
    const ids = [value];

    var payload = new FormData();
    ids.forEach((list) => payload.append("external_number_id[]", list));
    payload.append("type", "number");
    setLoader(true);
    var result = await post(`number/${id}/assign`, payload);
    result = await result.json();
    setCallAgain(true);
    setLoader(false);
  };

  const getType = (type) => {
    if (type === "platform" || type === "widget") return "platform";
    if (type === "team") return "team";
    if (type === "voicemail") return "voicemail";
    if (type === "user") return "teamMembers";
    if (type === "ivr") return "ivr";
    if (type === "number") return "externalNumber";
    return "";
  };

  return (
    <div className="call-routing-wrap">
      {virtualList && virtualList?.length > 0 ? (
        virtualList.map((list, index) => {
          return (
            <div className="call-routing-wrap" key={index}>
              <div className="call-routing">
                <div className="custom-control custom-switch text-white w-1/2 mt-1">
                  <h6>{list?.data?.phone_number}</h6>
                  <span>
                    {list?.data?.assigned?.name || "-"}
                    {/* {list?.data?.assigned?.name?.[0]?.split(" ")[0] || "-"} */}
                  </span>
                </div>
                <div className="custom-control custom-switch text-white w-1/2">
                  <select
                    value={
                      (currentType.index === index && currentType?.type) ||
                      getType(list?.type)
                    }
                    onChange={(e) => handleOnChange(e, index, list?.data?.id)}
                    className="w-full mb-1 text-gray-900 px-1 py-1 text-gray-700 rounded"
                    id="available_slot_time"
                  >
                    {AssignTo.map((time, index) => (
                      <option key={index} value={time.value}>
                        {time.text}
                      </option>
                    ))}
                  </select>
                  {list.type === "team" ? (
                    <select
                      value={list?.data?.assigned?.id}
                      onChange={(e) => handleTeamOnChange(e, list?.data?.id)}
                      className="w-full mb-4 text-gray-900 px-1 py-1 text-gray-700 rounded"
                      id="available_slot_time"
                    >
                      {list?.teamList?.map((time, index) => (
                        <option key={index} value={time.value}>
                          {time.text}
                        </option>
                      ))}
                    </select>
                  ) : null}

                  {list.type === "teamMembers" || list.type === "user" ? (
                    <select
                      value={list?.data?.assigned?.id}
                      onChange={(e) =>
                        handleTeamMembersOnChange(e, list?.data?.id)
                      }
                      className="w-full mb-4 text-gray-900 px-1 py-1 text-gray-700 rounded"
                      id="available_slot_time"
                    >
                      {list?.teamMembersList?.map((time, index) => (
                        <option key={index} value={time.value}>
                          {time.text}
                        </option>
                      ))}
                    </select>
                  ) : null}

                  {list.type === "externalNumber" || list.type === "number" ? (
                    <>
                      <select
                        value={list?.data?.assigned?.id}
                        onChange={(e) => externalNoChange(e, list?.data?.id)}
                        className="w-full mb-4 text-gray-900 px-1 py-1 text-gray-700 rounded"
                        id="available_slot_time"
                      >
                        {list?.externalNumbers?.map((time, index) => (
                          <option key={index} value={time.value}>
                            {time.text}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center no-active-call">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
          </svg>
          <h6>Virtual Numbers Are Not Available Yet.</h6>
        </div>
      )}
      {loader && (
        <div className="loader-class">
          <img src="/images/loader.gif" alt="loader" />
        </div>
      )}
    </div>
  );
}
