import moment from "moment";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import FullscreenModal from "../components/FullscreenModal";
import MessageLead from "../components/MessageLead";
import NoMessage from "../components/NoMessage";
import SourceSelector from "../components/SourceSelector";
import SwipeUpModal from "../components/SwipeUpModal";
import { getScritId, getWidgetId } from "../helpers/globals";
import { getConversations, getMessageBody } from "../helpers/messages";
import {
  deleteJson,
  get,
  postJson,
  putJson,
  successAlert,
} from "../helpers/network";
import { classNames, hideLoader, showLoader } from "../helpers/utilities";
import Chat from "./Chat";
import DownModel from "../components/DownModel";
let interval = "";
export const isToday = (date) => {
  const today = new Date();

  if (today.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

export default function Messages() {
  const [showSwipupModal, setShowSwipupModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState("open");
  const [conversations, setConversations] = useState(null);
  const [openCount, setOpenCount] = useState();
  const [closedCount, setClosedCount] = useState();
  const [selectedConversation, setSelectedConversation] = useState();
  const [teamMembers, setTeamMembers] = useState([]);
  const [scriptId, setScriptId] = useState();
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState("all");
  const [noMessage, setNoMessage] = useState(false);
  const [assignList, setAssignList] = useState([]);
  const [membersTeam, setMembersTeam] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [convo, setConvo] = useState({});
  const [openModal, setOpenModel] = useState(false);
  useEffect(() => {
    processList();
  }, [membersTeam, teamMembers]);

  useEffect(() => {
    return () => {
      hideLoader();
    };
  }, []);

  useEffect(() => {
    async function init() {
      setScriptId(await getScritId());
    }
    if (interval) {
      clearInterval(interval);
    }
    makeInterval();
    fetchMessges(true);
    if (teamMembers && !teamMembers?.length) {
      getTeamMembers();
    }
    if (sources && !sources?.length) {
      getUserNumbers();
    }
    getSymbols();
    if (membersTeam && !membersTeam?.length) {
      fetchTeams();
    }
    init();
    return () => {
      clearInterval(interval);
    };
  }, [activeFilter]);

  const makeInterval = () => {
    interval = setInterval(() => {
      fetchMessges(false);
    }, 7000);
  };

  const compareNotes = (a, b) => {
    const d1 = new Date(a.created_at);
    const d2 = new Date(b.created_at);
    return d1 - d2;
  };

  const getUserNumbers = async () => {
    var _sources = await get("user/caller-id-with-names");
    _sources = await _sources.json();
    if (_sources?.data && _sources?.data?.length) {
      const data = [];
      const datas = _sources?.data?.map((number, i) => {
        if (i === 0 && _sources?.data?.length > 1) {
          setSelectedSource(number?.number || number?.phone_number);
        }
        if (i + 1 !== _sources?.data?.length) {
          data.push({
            key: number?.name,
            value: number?.number || number?.phone_number,
            text: `${number?.name} ${number?.number || number?.phone_number}`,
          });
        }
      });
      data.unshift({
        key: "main",
        value: "all",
        text: "All",
      });
      setSources(data);
    }
  };
  const getSymbols = async () => {
    var symbols = await get("numbers/symbols");
  };

  const getTeamMembers = async () => {
    var members = await get("team/members");
    members = await members.json();
    setAdmin([members.data.admin]);
    setTeamMembers(members.data.members);
  };

  async function fetchMessges(_showLoader) {
    if (_showLoader) {
      setConversations([]);
      showLoader();
    }

    var messageState = 1;
    if (activeFilter == "snoozed") {
      messageState = 2;
    }
    if (activeFilter == "closed") {
      messageState = 3;
    }
    var coversationJson = await getConversations(messageState);

    setConversations(
      coversationJson?.conversations?.sort(compareNotes)?.reverse()
    );
    setOpenCount(coversationJson.open);
    setClosedCount(coversationJson.closed);

    hideLoader();
  }

  const homeStatDurationChanged = async (e, con) => {
    var result = await putJson(
      `conversation/${con.id}/update`,
      JSON.stringify({
        state: e?.target?.value,
      })
    );
    if (result?.status === 200) {
      fetchMessges(true);
    }
  };

  useEffect(() => {
    if (conversations && conversations?.length) {
      const someValue = conversations?.some(
        ({ source }) => source === selectedSource
      );
      setNoMessage(someValue);
      if (selectedSource === "all") {
        setNoMessage(true);
      }
    } else {
      setNoMessage(false);
    }
  }, [selectedSource, activeFilter, conversations]);

  const processList = () => {
    const allList = [...teamMembers, ...membersTeam, ...admin];
    const processList = allList.map((item) => {
      return {
        key: item.first_name?.length ? item.id : item.id + "-team",
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + " team",
      };
    });
    setAssignList(processList || []);
  };

  const fetchTeams = async () => {
    var members = await get("team/teams?limit=20000&offset=0");
    members = await members.json();
    setMembersTeam(members.data || []);
  };

  const handleChangeAssign = async (e, con) => {
    if (e?.target?.value) {
      let type = "App\\User";
      if (
        assignList
          ?.find(({ value }) => +value === +e?.target?.value)
          ?.key.toString()
          ?.includes("team")
      ) {
        type = "App\\Team";
      }
      const postData = {
        owner_type: type,
        owner_id: e?.target?.value,
        widget_id: getWidgetId(),
        ids: [con?.id],
      };
      var result = await postJson(
        "conversation/assign",
        JSON.stringify(postData)
      );
      result = await result.json();
      if (result) {
        fetchMessges(true);
      }
    }
  };

  const deleteConversation = async (con) => {
    var scriptId = await getScritId();
    if (scriptId && con?.id) {
      var payload = {
        ids: [con.id],
        script_id: scriptId,
      };
      showLoader();
      var result = await deleteJson(
        `conversation/delete`,
        JSON.stringify(payload)
      );
      if (result?.status === 200 || result?.statusText === "OK") {
        fetchMessges(false);
        hideLoader();
      } else {
        hideLoader();
      }
    }
  };
  //   let interval
  //   useEffect(() => {
  //     if (loading) {
  //       console.log('loadinqqqg', loading)
  //       // interval=setInterval(() => {
  //       //   setCount((prev)=>prev + 1)
  //       // }, 1000);
  //     }

  //     return ()=>{
  //      interval &&  clearInterval(interval)
  //       if (count > 1) {
  //         setOpenModel(true)
  //         setCount(0)

  //       } else {
  //         setCount(0)

  //         setSelectedConversation(convo);

  //       }
  //     }
  //   }, [loading]);
  //   const handleKeyDown = (conversation) => {
  //     setConvo(conversation)
  //     setLoading(true)

  //   }
  //   console.log('cou222nt', count)

  //   const handleKeyUp = () => {
  //     setLoading(false)

  //  console.log('count', count)
  //   }

  return (
    <>
      <section className="call-summary mh pb-24 h-full">
        <div className="m-s-top mb-0 mt-2 !top-16 z-10 select-mode">
          <SourceSelector
            sources={sources}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />

          <span
            onClick={() => {
              setShowSwipupModal(true);
            }}
            id="cc_message_btn"
            title="New Message"
          >
            <svg
              id="new-message-btn"
              data-tooltip-content="New message"
              title="Message"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 -mt-2 cursor-pointer text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
              />
            </svg>
          </span>
        </div>

        <div
          className="leads-tabs !top-32 sticky z-10"
          style={{ minHeight: "0px" }}
        >
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li
              onClick={() => {
                setActiveFilter("open");
              }}
              className="nav-item conv-filter"
              data-state="1"
            >
              <a
                className={classNames(
                  activeFilter == "open" ? "active" : "",
                  "nav-link"
                )}
                id="pendingleads"
                data-toggle="tab"
                href="#leads"
                role="tab"
                aria-controls="leads"
                aria-selected="true"
              >
                Open<div id="pendingleadscount"></div>
              </a>
            </li>
            <li
              onClick={() => {
                setActiveFilter("snoozed");
              }}
              className="nav-item conv-filter"
              data-state="3"
            >
              <a
                className={classNames(
                  activeFilter == "snoozed" ? "active" : "",
                  "nav-link"
                )}
                data-toggle="tab"
                href="#"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Snoozed
              </a>
            </li>
            <li
              onClick={() => {
                setActiveFilter("closed");
              }}
              className="nav-item conv-filter"
              data-state="3"
            >
              <a
                className={classNames(
                  activeFilter == "closed" ? "active" : "",
                  "nav-link"
                )}
                data-toggle="tab"
                href="#"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Closed
              </a>
            </li>
          </ul>
        </div>

        <div id="message-holder" className="leads-list">
          {conversations &&
            conversations.map((conversation) => (
              <div
                className={classNames(
                  selectedSource == "all" ||
                    selectedSource == conversation.source
                    ? "show"
                    : "hide",
                  "item conversation-item"
                )}
                key={conversation.id}
                data-id="116"
                data-index="0"
              >
                <div className="flex flex-start">
                  {conversation.owner_name && (
                    <img
                      // onMouseDown={() => handleKeyDown()}
                      // onMouseUp={() => handleKeyUp()}

                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                      className="h-8 w-8 owner-img"
                      src={`https://ui-avatars.com/api/?name=${conversation.owner_name}&rounded=true`}
                    />
                  )}
                  <div
                    className={`pl-3 w-full overflow-hidden ${
                      conversation.messages && conversation.messages.length > 0
                        ? "messag-img"
                        : ""
                    }`}
                  >
                    <div
                      className="company lead_name flex justify-space-between"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      <span>
                        {conversation && conversation.contact
                          ? conversation.contact.name
                          : conversation.friendly_name}
                      </span>
                      <span className="text-gray-400 flex">
                        <svg
                          onClick={() => {
                            setSelectedConversation(conversation);
                          }}
                          className="text-white mr-1"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.99992 1.33325C4.32394 1.33325 1.33325 4.32394 1.33325 7.99992C1.33325 11.6759 4.32394 14.6666 7.99992 14.6666C11.6759 14.6666 14.6666 11.6759 14.6666 7.99992C14.6666 4.32394 11.6759 1.33325 7.99992 1.33325ZM7.99992 2.33325C11.1355 2.33325 13.6666 4.86438 13.6666 7.99992C13.6666 11.1355 11.1355 13.6666 7.99992 13.6666C4.86438 13.6666 2.33325 11.1355 2.33325 7.99992C2.33325 4.86438 4.86438 2.33325 7.99992 2.33325ZM7.82544 3.99276C7.69295 3.99483 7.56669 4.0494 7.4744 4.14449C7.38212 4.23959 7.33135 4.36742 7.33325 4.49992V8.49992C7.33327 8.63252 7.38595 8.75969 7.47971 8.85346C7.57348 8.94722 7.70065 8.99991 7.83325 8.99992H10.4999C10.5662 9.00086 10.632 8.98862 10.6934 8.96391C10.7549 8.9392 10.8109 8.90252 10.8581 8.856C10.9053 8.80948 10.9427 8.75404 10.9683 8.69291C10.9939 8.63178 11.007 8.56618 11.007 8.49992C11.007 8.43366 10.9939 8.36805 10.9683 8.30693C10.9427 8.2458 10.9053 8.19036 10.8581 8.14384C10.8109 8.09732 10.7549 8.06064 10.6934 8.03593C10.632 8.01122 10.5662 7.99898 10.4999 7.99992H8.33325V4.49992C8.33421 4.433 8.32173 4.36656 8.29654 4.30455C8.27135 4.24255 8.23397 4.18623 8.18661 4.13893C8.13926 4.09164 8.08289 4.05433 8.02085 4.02922C7.95881 4.00411 7.89236 3.99171 7.82544 3.99276Z"
                            fill="#656B82"
                          ></path>
                        </svg>

                        {isToday(new Date(conversation?.created_at))
                          ? moment(conversation?.created_at).format("LT")
                          : moment(conversation?.created_at).format(
                              "DD/MM/YYYY"
                            )}
                      </span>
                    </div>

                    {conversation.unread_messages > 0 && (
                      <span
                        id="unread_notifications_count"
                        className="unread-badge"
                      >
                        {conversation.unread_messages} unread
                      </span>
                    )}
                    {/* {conversation.unread_messages > 0 && ( */}
                    {/* <span
                      id="unread_notifications_count"
                      className="delete-badge"
                    >
                      <a title="Delete">
                        <svg
                          id="delete-btn"
                          data-tooltip-content="Delete"
                          onClick={() => deleteConversation(conversation)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 text-white cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </a>
                    </span> */}
                    {/* )} */}
                    <p
                      className="text-gray-300 mb-2 p-tag"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      {conversation.messages &&
                        (conversation.messages.length > 0
                          ? getMessageBody(conversation.messages[0])
                          : "No messages")}
                    </p>

                    {/* <div
                      className="flex justify-end gap-2 text-sm end"
                      onClick={() => {
                        setSelectedConversation(conversation);
                      }}
                    >
                      <span className="text-gray-400">
                        via {conversation.source}
                      </span>
                    </div> */}
                  </div>
                </div>
                {/* <div className="flex justify-end gap-2 text-sm end mt-2">
                  <span
                    className={classNames(
                      conversation.state == "active"
                        ? "bg-green-500"
                        : "bg-red-500",
                      "status-assigned"
                    )}
                  >
                    <div className="">
                      <select
                        className="font-bold text-sm bg-transparent focus:outline-none"
                        onChange={(e) =>
                          homeStatDurationChanged(e, conversation)
                        }
                        defaultValue={
                          conversation?.state == "active"
                            ? "1"
                            : conversation?.state == "closed"
                            ? "3"
                            : "2"
                        }
                      >
                        <option value={"1"}>Open</option>
                        <option value={"3"}>Close</option>
                        <option value={"2"}>Snoozed</option>
                      </select>
                    </div>
                  </span>
                  <span
                    className={classNames("bg-green-500", "status-assigned")}
                  >
                    <div className="">
                      <select
                        className="font-bold text-sm bg-transparent focus:outline-none"
                        onChange={(e) => handleChangeAssign(e, conversation)}
                        defaultValue={
                          conversation?.owner_id
                            ? conversation?.owner_id?.toString()
                            : ""
                        }
                      >
                        <option value="">Unassigned</option>
                        {assignList &&
                          assignList?.length > 0 &&
                          assignList?.map((val, ind) => {
                            return (
                              <option key={ind} value={val?.value?.toString()}>
                                {val?.text}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </span>
                </div> */}
              </div>
            ))}
          {!noMessage && (
            <>
              <NoMessage
                sendSMSModal={() => {
                  setShowSwipupModal(true);
                }}
              />
            </>
          )}
        </div>
      </section>
      {selectedConversation && (
        <FullscreenModal
          showTopNav={true}
          component={
            <Chat
              handleReload={() => fetchMessges(true)}
              setShowSwipupModal={setShowSwipupModal}
              sources={sources}
              conversation={selectedConversation}
              onClosed={(refresh) => {
                if (refresh) {
                  fetchMessges(true);
                }
                setSelectedConversation(null);
              }}
              assignList={assignList || []}
              handleChangeAssign={handleChangeAssign}
            />
          }
        />
      )}
      {openModal && (
        <SwipeUpModal
          component={
            <DownModel scriptId={scriptId} teamMembers={teamMembers} />
          }
          onClosed={() => {
            setOpenModel(false);
          }}
        />
      )}
      {showSwipupModal && (
        <SwipeUpModal
          component={
            <MessageLead scriptId={scriptId} teamMembers={teamMembers} />
          }
          onClosed={() => {
            setShowSwipupModal(false);
          }}
        />
      )}
      <Tooltip anchorId="new-message-btn" />
    </>
  );
}
