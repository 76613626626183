import moment from "moment";
import { useEffect, useState } from "react";
import { get, post } from "../helpers/network";
import { classNames } from "../helpers/utilities";

export default function Notifications() {

    const [activeTab, setActiveTab] = useState("all");
    const [notifications, setNotifications] = useState();

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(()=>{
        if(notifications){
            markAsRead();
        }
    }, [notifications])

    const getNotifications = async () => {
        var result = await get("notification/in-app-notification");
        result = await result.json();

        setNotifications(result.data.all.data);
    }


    async function markAsRead(notificationIds) {
        var readNotifications = new FormData();

        for (var i = 0; i < notifications.length; i++) {
            var notificationId = notifications[i].id;
            readNotifications.append("ids[]", notificationId);
        }

        await post("notification/read", readNotifications);
    }


    return (
        <>
            <section className="notifications-page bottom-bar-padding h-full">
                <div className="notifications-tabs">
                    <div className="sticky top-16">
                        <div className="notifications-top">
                            <h1>Notifications</h1>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <span
                                    onClick={() => { setActiveTab("all") }}
                                    className={classNames("nav-link cursor-pointer", activeTab == "all" ? "active" : "")}
                                    id="all-tab"
                                    data-toggle="tab"
                                    href="#all"
                                    role="tab"
                                    aria-controls="all"
                                    aria-selected="true"
                                >All</span>
                            </li>
                            <li className="nav-item">
                                <span
                                    onClick={() => { setActiveTab("unread") }}
                                    className={classNames("nav-link cursor-pointer", activeTab == "unread" ? "active" : "")}
                                    id="unread-tab"
                                    data-toggle="tab"
                                    href="#unread"
                                    role="tab"
                                    aria-controls="unread"
                                    aria-selected="false"
                                >
                                    Unread
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="notifications-list" id="notification-list">

                        {notifications && notifications.map((notification) => (

                            <div key={notification.id} data-read={notification.read} className={classNames((activeTab == "unread" && notification.read == 1) ? "hide" : "show", "notification-item item")}>
                                <div className="avatar">
                                    <img src="images/avatar-user.png" alt="Avatar" />
                                </div>
                                <div className="info">
                                    <p>{notification.content}</p>
                                    <div className="time">{moment(notification.created_at).fromNow()}</div>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </section>

        </>
    );
}