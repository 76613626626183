import { CheckIcon, ClockIcon, NoSymbolIcon } from '@heroicons/react/24/outline'

export default function SingleTick({ status }) {
    return (
        <>
            <div className='chat-status-mark flex row'>
                {status == "delivered" || status == "read" ? <CheckIcon className='h-4 w-4 text-white' />:""}
                {status == "read" && <CheckIcon className='-ml-3 h-4 w-4 text-white' />}
                {status == "failed" && <NoSymbolIcon title='Failed' className='-ml-3 -mt-2 h-4 w-4 text-red-500' />}
                {status == "pending" && <ClockIcon title='Pending' className='-ml-3 -mt-1 h-4 w-4 text-white' />}
            </div>
        </>
    );
}