export default function LeadTabInsights({ lead }) {

    return (
        <>
            <div className="c-s-form">
                <p className="text-white">Visitor insight</p>
                <div className="item lead-info">
                    <label htmlFor="">Company:</label>
                    <p id="company_name" className="lead-info">{lead.company_name ? lead.company_name:"-"}</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Location:</label>
                    <p id="meeting_location" className="lead-info">{ lead.meeting_location ? lead.meeting_location:"-" }</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Type:</label>
                    <p id="type" className="lead-info">{ lead.type ? lead.type:"-" }</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Role:</label>
                    <p id="role" className="lead-info">{ lead.role ? lead.role:"-" }</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Industry:</label>
                    <p id="industry" className="lead-info">{ lead.industry ? lead.industry:"" }</p>
                </div>                
                
               
                <div className="item lead-info">
                    <label htmlFor="">IP address:</label>
                    <p id="ip_address" className="lead-info">{ lead.ip_address }</p>
                </div>
            </div>

            <div className="c-s-form">
                <p className="text-white">Technology :</p>
                <div className="item lead-info">
                    <label htmlFor="">IP Address:</label>
                    <p id="tech_ip" className="lead-info">{lead.ip_address}</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">OS Device:</label>
                    <p id="tech_os" className="lead-info">-</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Browser:</label>
                    <p id="tech_browser" className="lead-info">-</p>
                </div>
            </div>

            {/* <div className="c-s-form">
                <p className="text-white">Acquisition :</p>
                <div className="item lead-info">
                    <label htmlFor="">Acquisition:</label>
                    <p id="acquisition" className="lead-info"></p>
                </div>
            </div> */}

            <div className="c-s-form">
                <p className="text-white">Visited Page:</p>
                <div className="item lead-info">
                    <label htmlFor="">VISITED PAGE:</label>
                    <p id="visited_page" className="lead-info">-</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Last Seen:</label>
                    <p id="last_seen" className="lead-info">-</p>
                </div>
                <div className="item lead-info">
                    <label htmlFor="">Visit:</label>
                    <p id="visit" className="lead-info">-</p>
                </div>
            </div>
        </>
    );

}