import { useEffect, useState } from "react";

export function BusinessHourSettings({  }) {

    const [businessHoursEnabled, setBusinessHoursEnabled] = useState(false);

    return (
        <>
            <div className="settings-list">
                <div className="item hours">
                    <h2>Business hours</h2>
                    <div className="hours-list">
                        <div className="hours-list-item">
                            <h3>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 0C4.48603 0 0 4.48604 0 10C0 15.514 4.48603 20 10 20C15.514 20 20 15.514 20 10C20 4.48604 15.514 0 10 0ZM10 1.5C14.7033 1.5 18.5 5.2967 18.5 10C18.5 14.7033 14.7033 18.5 10 18.5C5.29669 18.5 1.5 14.7033 1.5 10C1.5 5.2967 5.29669 1.5 10 1.5ZM9.73828 3.98926C9.53954 3.99236 9.35016 4.07423 9.21173 4.21686C9.0733 4.3595 8.99715 4.55125 9 4.75V10.75C9.00002 10.9489 9.07904 11.1397 9.21969 11.2803C9.36034 11.421 9.55109 11.5 9.75 11.5H13.75C13.8494 11.5014 13.9481 11.483 14.0403 11.446C14.1325 11.4089 14.2164 11.3539 14.2872 11.2841C14.358 11.2143 14.4142 11.1312 14.4526 11.0395C14.4909 10.9478 14.5107 10.8494 14.5107 10.75C14.5107 10.6506 14.4909 10.5522 14.4526 10.4605C14.4142 10.3688 14.358 10.2857 14.2872 10.2159C14.2164 10.1461 14.1325 10.0911 14.0403 10.054C13.9481 10.017 13.8494 9.99859 13.75 10H10.5V4.75C10.5014 4.64962 10.4827 4.54997 10.4449 4.45695C10.4071 4.36394 10.3511 4.27946 10.28 4.20852C10.209 4.13758 10.1245 4.08161 10.0314 4.04395C9.93834 4.00629 9.83866 3.98769 9.73828 3.98926Z"
                                        fill="#7D90D7" />
                                </svg>
                                Business Hours enabled
                            </h3>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" value={businessHoursEnabled} className="cursor-pointer custom-control-input" id="enable_working_hours" onChange={(e) => { setBusinessHoursEnabled(e.target.checked); }} />
                                <label className="cursor-pointer custom-control-label" htmlFor="enable_working_hours"></label>
                            </div>
                        </div>
                    </div>
                </div>
                {businessHoursEnabled && <form id="working-schedule" className="item schedule">
                    <h2>Schedule</h2>
                    <div className="schedule-list workinghoutoptions" >
                        <div className="schedule-list-item">
                            <h3 id="monday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Monday" data-day="monday" id="mondayOffSwitch" />
                                    <label className="custom-control-label " htmlFor="mondayOffSwitch" ></label>
                                </div>
                                Monday
                            </h3>
                            <a className="time-input" href="#/">
                                <input id="monday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="monday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="Tuesday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Tuesday" data-day="tuesday" id="tuesdayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="tuesdayOffSwitch"></label>
                                </div>
                                Tuesday
                            </h3>
                            <a href="#/" className="time-input">
                                <input id="tuesday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="tuesday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="wednesday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Wednesday" data-day="wednesday" id="wednesdayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="wednesdayOffSwitch"></label>
                                </div>
                                Wednesday
                            </h3>
                            <a href="#/">
                                <input id="wednesday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="wednesday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="thursday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Thursday" data-day="thursday" id="thursdayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="thursdayOffSwitch"></label>
                                </div>
                                Thursday
                            </h3>
                            <a href="#/">
                                <input id="thursday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="thursday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="friday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Friday" data-day="friday" id="fridayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="fridayOffSwitch"></label>
                                </div>
                                Friday
                            </h3>
                            <a href="#/">
                                <input id="friday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="friday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="saturday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Saturday" i data-day="saturday" id="saturdayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="saturdayOffSwitch"></label>
                                </div>
                                Saturday
                            </h3>
                            <a href="#/">
                                <input id="saturday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="saturday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <div className="schedule-list-item">
                            <h3 id="Sunday">
                                <div className="dayoffswitch custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input working-day-off-switch Sunday" data-day="sunday" id="sundayOffSwitch" />
                                    <label className="custom-control-label" htmlFor="sundayOffSwitch"></label>
                                </div>
                                Sunday
                            </h3>
                            <a href="#/">
                                <input id="sunday_from" className="remove-day-check-box" step="any" type="time" />
                                <input id="sunday_to" className="remove-day-check-box" step="any" type="time" />
                            </a>
                        </div>
                        <input type="submit" className="text-center btn-main" value="Save" />
                    </div>

                </form>}
            </div>
        </>
    );

}