import { useEffect, useLayoutEffect, useState } from "react";
import Callback from "../components/Callback";
import FullscreenModal from "../components/FullscreenModal";
import LeadListItem from "../components/LeadListItem";
import MessageLead from "../components/MessageLead";
import Schedule from "../components/Schedule";
import SwipeUpModal from "../components/SwipeUpModal";
import { TransferLead } from "../components/TransferLead";
import { getWidgetId } from "../helpers/globals";
import { get, postJson } from "../helpers/network";
import { classNames, hideLoader, showLoader } from "../helpers/utilities";
import LeadDetails from "./LeadDetails";
import { useLocation } from "react-router";

export default function Leads({ user }) {
  const filters = {
    pending:
      "filter[type][!in]=TEXT_MESSAGE&filter[direction][is]=Inbound&filter[owner_id][is]=null&filter[status][!is]=3",
    assigned:
      "filter[type][!in]=TEXT_MESSAGE&filter[direction][is]=Inbound&filter[owner_id][!is]=null&filter[status][!is]=3",
    mine:
      "filter[type][!in]=TEXT_MESSAGE&filter[owner_id][is]=" +
      window.user.id +
      "&filter[direction][is]=Inbound&filter[status][!is]=3",
    all: "filter[type][!in]=TEXT_MESSAGE&filter[direction][is]=Inbound",
    closed:
      "filter[status][is]=3&filter[type][in]=TEXT_MESSAGE,LEAVE_MESSAGE&filter[direction][is]=Inbound",
  };

  const [activeFilter, setActiveFilter] = useState("pending");
  const [leads, setLeads] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedLead, setSelectedLead] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [schedulingSlots, setSchedulingSlots] = useState();

  const [showFullscreenModal, setShowFullscreenModal] = useState(false);
  const [showSwipupModal, setShowSwipupModal] = useState(false);

  const [swipeUpModalName, setSwipeUpModalName] = useState();

  const [pendingCount, setPendingCount] = useState();
  const [assignedCount, setAssignedCount] = useState();
  const [mineCount, setMineCount] = useState();
  const [closeCount, setCloseCount] = useState();
  const [allCount, setAllCount] = useState();
  const [assignList, setAssignList] = useState([]);
  const [admin, setAdmin] = useState([]);

  const [showSwipeUpModal, setShowSwipeUpModal] = useState(false);
  const [scriptId, setScriptId] = useState("");
  const [leadData, setLeadData] = useState();
const location=useLocation()


  useEffect(() => {
    getTeamMembers();
    getSchedulingSlots();
    getCounters();
    return () => {
      hideLoader();
    };
  }, []);

  useEffect(() => {
    getLeads(true);
  }, [activeFilter]);

  useEffect(() => {
    processList();
  }, [teamMembers]);

  const processList = () => {
    const allList = [...teamMembers, ...admin];
    const processList = allList.map((item) => {
      return {
        key: item.first_name?.length ? item.id : item.id + "-team",
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + " team",
      };
    });
    setAssignList(processList || []);
  };

  const getCounters = () => {
    async function getPendingCount() {
      var pendingLeadsCount = await get(
        "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
          filters["pending"]
      );
      pendingLeadsCount = await pendingLeadsCount.json();
      setPendingCount(pendingLeadsCount.data.leads.total);
    }

    async function getAssignedCount() {
      var assignedLeadsCount = await get(
        "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
          filters["assigned"]
      );
      assignedLeadsCount = await assignedLeadsCount.json();
      setAssignedCount(assignedLeadsCount.data.leads.total);
    }

    async function getMineCount() {
      var mineLeadsCount = await get(
        "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
          filters["mine"]
      );
      mineLeadsCount = await mineLeadsCount.json();
      setMineCount(mineLeadsCount.data.leads.total);
    }
    async function getCloseCount() {
      var closedLeadsCount = await get(
        "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
          filters["closed"]
      );
      closedLeadsCount = await closedLeadsCount.json();
      setCloseCount(closedLeadsCount.data.leads.total);
    }

    async function getAllCount() {
      var allLeadsCount = await get(
        "leads?limit=10&page=1&sort_field=id&sort_value=DESC&sort=-id&" +
          filters["all"]
      );
      allLeadsCount = await allLeadsCount.json();
      setAllCount(allLeadsCount.data.leads.total);
    }

    getPendingCount();
    getAssignedCount();
    getMineCount();
    getAllCount();
    getCloseCount();
  };

  const getSchedulingSlots = async () => {
    var widgetId = await getWidgetId();

    var availableSlots = await get(`widget/${widgetId}/available-slots`);
    availableSlots = await availableSlots.json();
    var _schedulingSlots = availableSlots.data;

    setSchedulingSlots(_schedulingSlots);
  };

  const getTeamMembers = async () => {
    var members = await get("team/members");
    members = await members.json();
    setAdmin([members.data.admin]);
    setTeamMembers(members.data.members);
  };

  const updateLead = (leadId, key, value) => {
    for (var i = 0; i < leads.length; i++) {
      if (leads[i].id == leadId) {
        leads[i][key] = value;
        break;
      }
    }
  };

  const loadLeads = (tab) => {
    setActiveFilter(tab);
  };

  const getLeads = async (_showLoader) => {
    if (_showLoader) {
      setLeads([]);
      showLoader();
    }

    var url =
      "leads?limit=" +
      limit +
      "&page=" +
      page +
      "&sort_field=id&sort_value=DESC&sort=-id&" +
      filters[activeFilter];

    const result = await get(url);
    var resultJson = await result.json();
    setLeads(resultJson?.data?.leads?.data || []);
    if (selectedLead) {
      let id = resultJson?.data?.leads?.data?.find(
        ({ id }) => id === selectedLead?.id
      );
      id && setSelectedLead(id);
    }

    if (_showLoader) {
      hideLoader();
    }
  };

  const onScheduleCall = (lead) => {
    if (lead) {
      setSelectedLead(lead);
    }

    setShowSwipupModal(true);
    setSwipeUpModalName("scheduleCall");
  };

  const onLeadTransfer = (lead) => {
    if (lead) {
      setSelectedLead(lead);
    }

    setShowSwipupModal(true);
    setSwipeUpModalName("transfer");
  };


useLayoutEffect(()=>{
  if(location.state){
    setSelectedLead(location.state);
    setShowFullscreenModal(true);
  }
  },[location.state])

  const onSendMessage = () => {
    setShowSwipupModal(true);
    setSwipeUpModalName("sendmessage");
  };

  const onCallback = (lead) => {
    if (lead) {
      setSelectedLead(lead);
    }

    setShowSwipupModal(true);
    setSwipeUpModalName("callback");
  };

  const swipeUpComponent = () => {
    switch (swipeUpModalName) {
      case "callback":
        return <Callback teamMembers={teamMembers} lead={selectedLead} />;
        break;
      case "scheduleCall":
        return (
          <Schedule
            teamMembers={teamMembers}
            slots={schedulingSlots}
            lead={selectedLead}
          />
        );
        break;
      case "sendmessage":
        return <MessageLead teamMembers={teamMembers} lead={selectedLead} />;
        break;
      case "transfer":
        return (
          <TransferLead
            refresh={() => {
              setShowSwipupModal(false);
              getLeads(true);
              getCounters();
            }}
            teamMembers={teamMembers}
            lead={selectedLead}
          />
        );
        break;
      default:
        return <Callback />;
        break;
    }
  };

  const deleteLead = async () => {
    var areyousure = window.confirm("Are you sure?");
    if (!areyousure) {
      return;
    }

    var disableDeleteBtn = document.getElementById("lead-delete");
    disableDeleteBtn.disabled = true;
    disableDeleteBtn.innerHTML = "Please wait...";

    await postJson(
      `leads/delete-leads`,
      JSON.stringify({
        ids: [selectedLead.id],
      })
    );

    setShowFullscreenModal(false);
    getLeads(true);
  };

  return (
    <>
      <section className="leads bottom-bar-padding">
        <div className="leads-tabs">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li
              onClick={() => {
                loadLeads("pending");
              }}
              className="nav-item"
            >
              <a
                className={classNames(
                  activeFilter == "pending" ? "active" : "",
                  "nav-link"
                )}
                id="pendingleads"
                data-toggle="tab"
                href="#leads"
                role="tab"
                aria-controls="leads"
                aria-selected="true"
              >
                Pending<div id="pendingleadscount">({pendingCount})</div>
              </a>
            </li>
            <li
              onClick={() => {
                loadLeads("assigned");
              }}
              className="nav-item"
            >
              <a
                className={classNames(
                  activeFilter == "assigned" ? "active" : "",
                  "nav-link"
                )}
                id="inprogessleads"
                data-toggle="tab"
                href="#qualified"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Assigned<div id="assignedleadscount">({assignedCount})</div>
              </a>
            </li>
            <li
              onClick={() => {
                loadLeads("closed");
              }}
              className="nav-item"
            >
              <a
                className={classNames(
                  activeFilter == "closed" ? "active" : "",
                  "nav-link"
                )}
                id="inprogessleads"
                data-toggle="tab"
                href="#qualified"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Closed<div id="assignedleadscount">({closeCount || 0})</div>
              </a>
            </li>
            <li
              onClick={() => {
                loadLeads("mine");
              }}
              className="nav-item"
            >
              <a
                className={classNames(
                  activeFilter == "mine" ? "active" : "",
                  "nav-link"
                )}
                id="myleads"
                data-toggle="tab"
                href="#qualified"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                Mine<div id="mineleadscount">({mineCount})</div>
              </a>
            </li>
            <li
              onClick={() => {
                loadLeads("all");
              }}
              className="nav-item"
            >
              <a
                className={classNames(
                  activeFilter == "all" ? "active" : "",
                  "nav-link"
                )}
                id="allleads"
                data-toggle="tab"
                href="#qualified"
                role="tab"
                aria-controls="qualified"
                aria-selected="false"
              >
                All<div id="allleadscount">({allCount})</div>
              </a>
            </li>
          </ul>

          <div className="leads-list" id="my-leads-list">
            {leads &&
              leads.length > 0 &&
              leads.map((lead, i) => (
                <LeadListItem
                  activeTab={activeFilter}
                  onLeadTransfer={onLeadTransfer}
                  onCallback={onCallback}
                  onScheduleCall={onScheduleCall}
                  refresh={() => {
                    getLeads(true);
                    getCounters();
                  }}
                  onLeadClicked={() => {
                    setShowFullscreenModal(true);
                    setSelectedLead(lead);
                  }}
                  setShowSwipeUpModal={setShowSwipeUpModal}
                  setScriptId={setScriptId}
                  setLeadData={setLeadData}
                  lead={lead}
                  key={i}
                />
              ))}
          </div>

          <div className="transfer-modal hidden" id="transfer-modal">
            Change Lead Owner
            <svg
              id="close-transfer-modal"
              className="-mt-4 w-6 h-6 float-right m-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div>
              <select className="w-full mb-6 text-gray-900" id="lead_owner">
                <option value="">Unassigned</option>
                <option disabled id="owner_select_option">
                  Loading...
                </option>
              </select>
            </div>
            <div>
              <button id="transfer-lead" className="btn-main mt-4">
                Transfer
              </button>
            </div>
          </div>
        </div>
      </section>
      {showFullscreenModal && (
        <FullscreenModal
          showTopNav={true}
          component={
            <LeadDetails
              onDelete={deleteLead}
              teamMembers={teamMembers}
              onSendMessage={onSendMessage}
              onCallback={onCallback}
              onScheduleCall={onScheduleCall}
              updateLead={updateLead}
              lead={selectedLead}
              onClosed={() => {
                setShowFullscreenModal(false);
              }}
              refresh={() => {
                getLeads(true);
                getCounters();
              }}
              assignList={assignList}
            />
          }
        />
      )}
      {showSwipupModal && (
        <SwipeUpModal
          component={swipeUpComponent()}
          onClosed={() => {
            setShowSwipupModal(false);
          }}
        />
      )}

      {showSwipeUpModal && (
        <SwipeUpModal
          component={<MessageLead scriptId={scriptId} lead={leadData} />}
          onClosed={() => {
            setShowSwipeUpModal(false);
          }}
        />
      )}
    </>
  );
}
