import React, { useEffect, useState } from "react";
import { getWidgetId } from "../helpers/globals";
import { errorAlert, get, post, postJson, successAlert } from "../helpers/network";
import Switch from "react-switch";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import SwipeUpModal from "./SwipeUpModal";

const CallForwarding = () => {
  const [info, setInfo] = useState({});
  const [data, setData] = useState({})
  const [addNew, setAddNew] = useState(false)
  const [targetNumber, setTargetNumber] = useState('')
  const [phoneCountry, setPhoneCountry] = useState('')
  const [openVerify, setOpenVerify] = useState(false)
  const [mobileStatus, setMobileStatus] = useState(
    window?.user?.mobile_app_call_status
  );
  const [verificationCode, setVerificationCode] = useState('')
  // const getCallForwadingSettings = async () => {
  //   const w_id = await getWidgetId();
  //   let result = await get(`widget/get-widget-call-settings?widget_id=${w_id}`);
  //   result = await result.json();
  //   setInfo(result?.data);
  // };
  // useEffect(() => {
  //   getCallForwadingSettings();
  // }, []);
  const toggleWeb = async (val) => {
    //this.setState({isLoading: true})
    const id = window.user?.id;

    const data = val ? 0 : 1;

    const url = await get(`user/update-browser-availability/${id}/${data}`);
    getCallForwadingSettings();
  };
  const toggleMobile = async (val) => {

    const formdata = new FormData()
    formdata.append('status', val ? 0 : 1)
    const url = await post(`user/mobile-call-status`, formdata);
    setMobileStatus(val ? 0 : 1);
  };
  
 const  togglePhone = async (val, number) => {
    //this.setState({isLoading: true})


const formdata=new FormData()
   formdata.append('phone_number',number)
   formdata.append('status', val ? 0 : 1)
   const url = await post(`user/change-number-status`, formdata)

  
  }
  const addNumforVerify = async() => {
    // if (!targetNumber){

    // }

    const id = localStorage?.getItem("id");

    const formdata = new FormData()
    formdata.append('user_id', id)
    formdata.append('phone_country', phoneCountry)
    formdata.append('full_number', targetNumber)
    formdata.append('phone_number', targetNumber)
 

    let url = await post(`user/send-verification-phone-number`, formdata)
    url = await url.json();
    if (url?.status !==200){
      setOpenVerify(true)
    }

  }
  
  const verifyNumber =async () => {

    const formData=new FormData()
    formData.append('verification_code', verificationCode)


    const url = await post(`user/verify-user-phone-number` ,formData)

  
  }

  useEffect(() => {
    getCallForwadingSettings();
  }, []);

  const handleUpdateStatus = async (e, index) => {
    if (info[index]?.phone_number) {
      const payload = {
        phone_number: info[index]?.phone_number,
        status: e?.target?.checked ? 1 : 0,
      };
      var result = await postJson(
        "user/change-number-status",
        JSON.stringify(payload)
      );
      result = await result.json();
      if (result?.data) {
        successAlert("Forward All Calls Status Updated");
        getCallForwadingSettings();
      }
    } else {
      errorAlert("Please Add Call Forwarding Number First");
    }
  };
console.log('data', data)
  const getCallForwadingSettings = async () => {
    const w_id = await getWidgetId();
    let result = await get(`widget/get-widget-call-settings?widget_id=${w_id}`);
    result = await result.json();
    setData(result?.data)
    if (
      result?.data?.available_forward_no &&
      result?.data?.available_forward_no?.length
    ) {
      setInfo(result?.data?.available_forward_no || []);
    } else {
      setInfo([]);
    }
  };
console.log('info', info)
  return (
    <>
      <div className="call-forwarding-wrap">
        <div className="call-forwarding">
          <h6>Web Call</h6>
          <div className="custom-control custom-switch">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={data?.receive_call_browser}
              onChange={() => toggleWeb(data?.receive_call_browser)}
            />
          </div>
        </div>
        <div className="call-forwarding">
          <h6>Mobile App</h6>
          <div className="custom-control custom-switch">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              checked={mobileStatus}
              onChange={() => toggleMobile(mobileStatus)}
            />
          </div>
        </div>
        <div className="call-routing-wrap">
          {info && info?.length > 0 ? (
            info.map((list, index) => {
              return (
                <div className="call-routing-wrap" key={index}>
                  {index === 0 && (
                    <p className="">
                      Forward to External number (if toggle on show the number and
                      additional toggle enable or disable)
                    </p>
                  )}
                  <div className="call-routing">
                    <h6>{list?.phone_number}</h6>
                    <div className="custom-control custom-switch text-white">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`customSwitchForward${index}`}
                        checked={+list?.active === 1 ? true : false}
                        onChange={(e) => handleUpdateStatus(e, index)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`customSwitchForward${index}`}
                      ></label>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-center no-active-call">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
              </svg>
              <h6>Call Forwading Numbers Are Not Available Yet.</h6>
            </div>
          )}
        </div>
        {/* {info?.available_forward_no?.map((val) => (
          <div className="call-forwarding">
            <h6>{val?.phone_number}</h6>
            <div className="custom-control custom-switch">
              <Switch
                uncheckedIcon={false}
                checkedIcon={false}
                checked={val?.active}
                onChange={() => togglePhone(val?.active, val?.phone_number)}
              />
            </div>
          </div>
        ))} */}
        <div>
        {addNew ? 
        <div style={{display:'flex'}}>
            <PhoneInput
              international
              value={targetNumber}

              enableSearch={true}

              onChange={(e, data) => {
                setPhoneCountry("+" + data?.dialCode);
                setTargetNumber(e);
              }}
              /><button onClick={() => addNumforVerify()} style={{ backgroundColor: '#fff', color: '#000', padding: '7px' }}> Verify</button></div>  :''  
      
      
      }
      </div>
        <button onClick={()=>setAddNew(!addNew)} style={{backgroundColor:'#fff', color:'#000', padding:'7px' , marginTop:'10px'}}> Add number</button>
      </div>
      {openVerify && 

          <SwipeUpModal
            component={<>
          <h6 className="text-lg">
          Verify your number
          </h6>
          
          <p>We have sent the verification code to +{targetNumber}</p>

          <br/>
          <p>Enter your verification code</p>
          
          <input onChange={(e) => setVerificationCode(e.target.value)} style={{color:'#000'}} placeholder="Enter code"/>
          <div className="p-4 d-flex w-100" style={{justifyContent:'space-around'}}>
            <button onClick={() => verifyNumber()} style={{ backgroundColor: '#fff', color: '#000', padding: '7px' }}> Submit</button>
            <button onClick={() => setOpenVerify(false) } style={{ backgroundColor: '#fff', color: '#000', padding: '7px' }}> Cancel</button>

          </div>
            </>
            }
            onClosed={() => {
              setOpenVerify(false);
            }}
          />

      
      }
    </>
  );
};

export default CallForwarding;
