var global_cache = {};

export function getFromCache(url){
    return global_cache[url];
}

export  function saveToCache(url, data){
    global_cache[url] = data;

    return data;
}

export function clearCache(){
    global_cache = {};
}