import moment from "moment";
import React, { useEffect, useState } from "react";
import { get } from "../helpers/network";
import Parser from "html-react-parser";
import { hideLoader, showLoader } from "../helpers/utilities";
import { useNavigate } from "react-router";

const answerd = ["completed"];
const voicemail = ["VOICEMAIL", "voicemail"];
const type = [
  {
    key: 1,
    text: "SCHEDULE",
    value: "SCHEDULE_CALL",
    className: "green",
  },
  {
    key: 2,
    text: "LEAVE MESSAGE",
    value: "LEAVE_MESSAGE",
    className: "mahogany",
  },
  {
    key: 3,
    text: "LIVE CALL",
    value: "LIVE_CALL",
    className: "teal",
  },
  {
    key: 4,
    text: "DIGITAL",
    value: "DIGITAL_CALL",
    className: "blue",
  },
  {
    key: 5,
    text: "UNSUPPORTED COUNTRY",
    value: "UNSUPPORTED_COUNTRY",
    className: "orangey",
  },
  {
    key: 6,
    text: "USER BLACKLISTED",
    value: "USER_BLACKLISTED",
    className: "umber",
  },
  {
    key: 7,
    text: "FORWARDED",
    value: "FORWARDED_CALL",
    className: "pastel-orange",
  },
];

const roleList = ["Admin", "admin", "Owner", "owner"];

export default function Logs() {
  const navigate=useNavigate()
  const [activeButton, setActiveButton] = useState("inbound");
  const [activeSubButton, setActiveSubButton] = useState("missed");
  const [initalCall, setInitalCall] = useState(true);
  const [switchData, setSwitchData] = useState({
    isOpen: false,
    role: "",
  });
  const [data, setData] = useState({
    inbound: [],
    outbound: [],
  });

  useEffect(() => {
    getCalls();
    setSwitchData({
      ...switchData,
      role: window.user?.role?.name,
    });
    return () => {
      hideLoader();
    };
  }, []);

  useEffect(() => {

getCalls()
  }, [activeButton,activeSubButton]);

  const onStatusChange = (e) => {
    setSwitchData({
      ...switchData,
      isOpen: e.target.checked,
    });
  };


  const getCalls = async () => {
    initalCall && setInitalCall(false);
    let URL = `leads?sort=-id&filter[agent][is]=${
      window?.user?.first_name + " " + window?.user?.last_name
    }&page=1&limit=150`;
    let URL1 = `leads?sort=-id&page=1&limit=150`;
    if (activeButton=== "inbound"){
      
    
        
      if(activeSubButton==='missed') {
        URL = `${
          switchData.isOpen ? URL1 : URL
        }&filter[type][!in]=SCHEDULE_CALL,TEXT_MESSAGE,LEAVE_MESSAGE&filter[direction][is]=Inbound&filter[final_status][!is]=completed`;
        
      } else if (activeSubButton === 'answered'){
        
        URL = `${
          switchData.isOpen ? URL1 : URL
        }&filter[type][!in]=SCHEDULE_CALL,TEXT_MESSAGE,LEAVE_MESSAGE&filter[direction][is]=Inbound&filter[final_status][is]=completed`;
      }
    }


   else  if (activeButton === "outbound") {

        if (activeSubButton === 'missed') {

        URL = `${
          switchData.isOpen ? URL1 : URL
        }&filter[type][!in]=SCHEDULE_CALL&filter[direction][is]=Outbound&filter[final_status][!is]=completed`;
        } else if (activeSubButton === 'answered'){
          URL = `${switchData.isOpen ? URL1 : URL
            }&filter[type][!in]=SCHEDULE_CALL&filter[direction][is]=Outbound&filter[final_status][is]=completed`;
        } else if (activeSubButton === 'voiceMail'){
          URL = `${switchData.isOpen ? URL1 : URL
            }&filter[type][!in]=SCHEDULE_CALL&filter[direction][is]=Outbound&filter[final_status][is]=voicemail`;
        }
      }

    showLoader();
    const res = await get(URL);
    const resJson = await res.json();
    if (resJson?.data?.leads?.data) {
      hideLoader();
      switch (activeButton) {
        case "inbound":
          setData({
            ...data,
            inbound: resJson?.data?.leads?.data || [],
          });
          break;

        case "outbound":
          setData({
            ...data,
            outbound: resJson?.data?.leads?.data || [],
          });
          break;

        default:
          setData({
            ...data,
            inbound: resJson?.data?.leads?.data || [],
          });
          break;
      }
    } else {
      hideLoader();
    }
  };
  const addUrlProtocol = (_url) => {
    if (_url.indexOf("http://") == 0 || _url.indexOf("https://") == 0) {
      return _url;
    }
    return "https://" + _url;
  };

  const getUrlHost = (_url) => {
    try {
      return new URL(_url).host;
    } catch (e) {
      return _url;
    }
  };

  return (
    <div className="text-white w-100">
      <div className="d-flex justify-content-between">
        <h3 className="logs-title">Calls</h3>
        <div className="d-flex justify-content-around tab-button w-100">
          <button
            className={activeButton === "inbound" ? "active" : ""}
            onClick={() => setActiveButton("inbound")}
          >
            Inbound
          </button>
          <button
            className={activeButton === "outbound" ? "active" : ""}
            onClick={() => setActiveButton("outbound")}
          >
            Outbound
          </button>
        </div>
       {roleList.includes(switchData?.role) ? (
          <div className="d-flex align-items-center">
            {/* <span className="mr-2">All</span> */}
            <div className="custom-control custom-switch bound-switch">
              <input
                checked={switchData.isOpen}
                onChange={onStatusChange}
                type="checkbox"
                className="custom-control-input"
                id="inOutBondSwitch"
              />
              <label
                className="custom-control-label"
                htmlFor="inOutBondSwitch"
              ></label>
            </div>
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-around tab-button">
      {activeButton==='inbound'?  <><button
          className={activeSubButton === "missed" ? "active" : ""}
          onClick={() => setActiveSubButton("missed")}
        >
          Missed
        </button>
        <button
          className={activeSubButton === "answered" ? "active" : ""}
          onClick={() => setActiveSubButton("answered")}
        >
          Answered
          </button></>:
        
          <><button
            className={activeSubButton === "missed" ? "active" : ""}
            onClick={() => setActiveSubButton("missed")}
          >
            Missed
          </button>
            <button
              className={activeSubButton === "answered" ? "active" : ""}
              onClick={() => setActiveSubButton("answered")}
            >
              Answered
            </button>
            <button
              className={activeSubButton === "voiceMail" ? "active" : ""}
              onClick={() => setActiveSubButton("voiceMail")}
            >
              Voicemail
            </button>
            </>
        }
      </div>
      <ul className="logs-list">
        {data?.[activeButton] &&
          data?.[activeButton]?.length > 0 &&
          data?.[activeButton]?.map((list, index) => {
            const time = new Date(+list?.duration * 1000)
              ?.toISOString()
              ?.substr(11, 8)
              ?.split(":");
            const name = list?.customer_name || list?.contact_name;
            return (
              <li className="d-flex justify-content-between" onClick={() => navigate('/leads', {state: list?.id})} key={index}>
                <div>
                  <h6>{name}</h6>
                  <p>
                    {list?.created_at
                      ? moment(list?.created_at).format("MMM DD, YYYY hh:mm A")
                      : "-"}{" "}
                  </p>
                  {+list?.duration ? (
                    <p className="time">
                      {`${time?.[0] * 1 ? time?.[0] + " Hours " : ""} ${
                        time?.[1] * 1 ? time?.[1] + " " + "Minutes" + " " : ""
                      }${time?.[2] * 1 ? time?.[2] + " " + "Seconds" : ""}`}
                    </p>
                  ) : null}
                  {list?.recording_url ? (
                    <audio controls style={{ height: "25px", width: "180px" }}>
                      <source src={list?.recording_url}></source>
                    </audio>
                  ) : (
                    ""
                  )}
                  {voicemail?.includes(list?.type) ? (
                    <span className="voicemail">Voicemail</span>
                  ) : answerd.includes(list?.final_status) ? (
                      <span className="answerd">Answered By {list?.agent?.split(" ")[0]}</span>
                  ) : (
                        <span className="missed">Missed By {list?.agent?.split(" ")[0]}</span>
                  )}
                </div>
                <div>
                  <p>{list?.id ? list?.id : ""}</p>
                  {/* <p title={list?.agent}>
                    {list?.agent ? list?.agent?.split(" ")[0] : ""}
                  </p> */}
                  {activeButton !== "outbound" ? (
                    <>
                      <p title={list?.source}>
                        {list?.source
                          ? Parser(
                              `<a target="_blank" href="${addUrlProtocol(
                                list?.source
                              )}">${getUrlHost(list?.source)}</a>`
                            )
                          : ""}
                      </p>
                      <p>
                        <span
                          className={
                            type.find(({ value }) => value === list?.type)
                              ?.className || "answerd"
                          }
                        >
                          {" "}
                          {type.find(({ value }) => value === list?.type)
                            ?.text || list?.type}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p>Cost:${list?.cost}</p>
                      <a className="outbond-number">{list?.sim_call_id}</a>
                    </>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
