export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function showLoader() {
  if (document.querySelector("#loader")?.style) {
    document.querySelector("#loader").style.display = "block";
  }
}

export function isExtension() {
  return (
    window.location.hostname != "localhost" &&
    window.location.hostname != "dialer.limecall.com"
  );
}

export function hideLoader() {
  if (document.querySelector("#loader")?.style?.display) {
    document.querySelector("#loader").style.display = "none";
  }
}

export function ucfirst(str) {
  if (!str) throw "Cannot capitalize undefined";
  let strings = str.split(" ");
  return strings
    .map((string) => string.charAt(0).toLocaleUpperCase() + string.slice(1))
    .join(" ");
}
