import moment from "moment";
import React from "react";

export default function LeadLogs({ leadInfo }) {
  return (
    <>
      <div className="leads-list" id="notes-list">
        {leadInfo?.logs &&
          leadInfo?.logs?.length > 0 &&
          leadInfo?.logs?.map((log, i) => (
            <>
              <div key={i} className="item">
                <div className="item-list-log">
                  <span className="d-flex">
                    <svg
                      className="text-white mr-1"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99992 1.33325C4.32394 1.33325 1.33325 4.32394 1.33325 7.99992C1.33325 11.6759 4.32394 14.6666 7.99992 14.6666C11.6759 14.6666 14.6666 11.6759 14.6666 7.99992C14.6666 4.32394 11.6759 1.33325 7.99992 1.33325ZM7.99992 2.33325C11.1355 2.33325 13.6666 4.86438 13.6666 7.99992C13.6666 11.1355 11.1355 13.6666 7.99992 13.6666C4.86438 13.6666 2.33325 11.1355 2.33325 7.99992C2.33325 4.86438 4.86438 2.33325 7.99992 2.33325ZM7.82544 3.99276C7.69295 3.99483 7.56669 4.0494 7.4744 4.14449C7.38212 4.23959 7.33135 4.36742 7.33325 4.49992V8.49992C7.33327 8.63252 7.38595 8.75969 7.47971 8.85346C7.57348 8.94722 7.70065 8.99991 7.83325 8.99992H10.4999C10.5662 9.00086 10.632 8.98862 10.6934 8.96391C10.7549 8.9392 10.8109 8.90252 10.8581 8.856C10.9053 8.80948 10.9427 8.75404 10.9683 8.69291C10.9939 8.63178 11.007 8.56618 11.007 8.49992C11.007 8.43366 10.9939 8.36805 10.9683 8.30693C10.9427 8.2458 10.9053 8.19036 10.8581 8.14384C10.8109 8.09732 10.7549 8.06064 10.6934 8.03593C10.632 8.01122 10.5662 7.99898 10.4999 7.99992H8.33325V4.49992C8.33421 4.433 8.32173 4.36656 8.29654 4.30455C8.27135 4.24255 8.23397 4.18623 8.18661 4.13893C8.13926 4.09164 8.08289 4.05433 8.02085 4.02922C7.95881 4.00411 7.89236 3.99171 7.82544 3.99276Z"
                        fill="#656B82"
                      ></path>
                    </svg>
                    {log?.created_at
                      ? moment(log?.created_at).format("DD-MM-YYYY h:m A")
                      : ""}
                  </span>
                  <span>{log?.action?.userName}</span>
                </div>

                <span className="logs-details">
                  {log?.action?.data?.replace(log?.action?.userName, "")}
                </span>
              </div>
            </>
          ))}
      </div>
    </>
  );
}
