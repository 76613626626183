import Pusher from "pusher-js";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Logs from "./components/Logs";
import { deploymentType, getWidgetId } from "./helpers/globals";
import { isExtension } from "./helpers/utilities";
import { Dialer } from "./pages/Dialer";
import Home from "./pages/Home";
import Leads from "./pages/Leads";
import Messages from "./pages/Messages";
import Notifications from "./pages/Notifications";
import { Settings } from "./pages/Settings";
import Signup from "./pages/Signup";

function Page({ user }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (isExtension()) {
      navigate("/");
    }

    async function init() {
      const type = deploymentType();
      const id =
        type === "development"
          ? process.env.REACT_APP_PUSHER_ID || "7b262bf6c81fabf9536b"
          : process.env.REACT_APP_PUSHER_ID || "06732b853776c74d2631";
      const pusher = new Pusher(id, {
        cluster: "eu",
      });

      Pusher.log = (msg) => {
        console.log(msg);
      };

      const widgetId = await getWidgetId();
      const channel = pusher.subscribe(`conversation-channel-${widgetId}`);
      channel.bind_global(function (event, data) {
        console.log(`The event ${event} was triggered with data ${data}`);
      });
    }

    init();
  }, []);

  return (
    <>
      <div className="h-full overflow-hidden" style={{ minWidth: "360px" }}>
        <div id="loader" style={{ zIndex: 99 }}>
          <img src="/images/loader.gif" />
        </div>

        <Header user={user} />
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/dialer" element={<Dialer user={user} />} >
            <Route path=":any" exact={false} element={<Dialer user={user} />} />
          </Route>
        
          <Route path="/logs" element={<Logs user={user} />} />
          <Route path="/leads" element={<Leads user={user} />} />
          <Route path="/messages" element={<Messages user={user} />} />
          <Route path="/settings" element={<Settings user={user} />} />
          <Route
            path="/notifications"
            element={<Notifications user={user} />}
          />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default Page;
