import { useEffect, useState } from "react";
import { getCallerIds } from "../helpers/messages";

export default function SourceSelector({
  sources,
  selectedSource,
  setSelectedSource,
  omitAll,
}) {
  return (
    <>
      <select
        value={selectedSource ? selectedSource : ""}
        onChange={(e) => {
          setSelectedSource(e.target.value);
        }}
        className="w-full py-2 rounded py-1 px-2 w-54 text-gray-900"
        id="message_from_selector"
      >
        {sources &&
          sources.map((callerId, index) => (
            <option key={index} value={callerId?.value || callerId}>
              {callerId?.text || callerId}
            </option>
          ))}
      </select>
    </>
  );
}
