import { useState } from "react";
import GoogleButton from "../components/GoogleButton";
import {
  appClientID,
  appClientSecret,
  errorAlert,
  get,
  post,
  setAccessToken
} from "../helpers/network";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';

export default function VerifyEmail() {
  const navigate = useNavigate()
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [otp, setOtp] = useState('');
  const onLogin = async (e) => {
    e.preventDefault();

    var formdata = new FormData();
    formdata.append("client_id", appClientID);
    formdata.append("client_secret", appClientSecret);
    formdata.append("grant_type", "password");
    formdata.append("email", email);
    formdata.append("name", name);

    const result = await get(`check-if-spam-email?email=${email}`);
    const resultJson = await result.json();
    if (resultJson.data.access_token) {
      setAccessToken(resultJson.data.access_token);
      // window.location.reload();
      window.location.href = "/verify-email";

    } else {
      errorAlert("Login failed");
    }
  };

  return (
    <>
      <section className="login h-full flex flex-col" >
        <div className="logo">
          <a href="#/">
            <img src="images/logo-limecall.svg" alt="Logo LimeCall" />
          </a>
        </div>
        <h1>Create a new account</h1>

        
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props}  style={{width:'2rem',height:'rem'}}/>}
          />

          <button onClick={() => navigate('/signup')} type="submit" className="btn-main">
           Verify
          </button>
      </section>

    </>
  );
}
