import { useEffect, useState } from "react";
import { getWidgetId } from "../helpers/globals";
import { errorAlert, getCountryCode, getCountryList, post, successAlert } from "../helpers/network";
import CountryData from "../helpers/data/countryCode.json";
import PhoneInput from "react-phone-input-2";

export default function Callback({ lead, teamMembers }) {

    const [isLoading, setIsLoading] = useState(false);
    const [teamMember, setTeamMember] = useState();
    const [phoneNumber, setPhoneNumber] = useState(lead.phone_number);
    const [allowedCountries, setAllowedCountries] = useState([]);

    useEffect(()=>{
        getWidgetCountries();
        if(teamMembers && teamMembers.length > 0){
            setTeamMember(teamMembers[0].id);
        }
    }, []);

    const callback = async () => {
        setIsLoading(true);
        var payload = new FormData();
        payload.append("phone_number", `+${phoneNumber}`);
        payload.append("country_code", getCountryCode(`+${phoneNumber}`));
        payload.append("to_agent", teamMember);

        var result = await post("customer-direct-call", payload);
        result = await result.json();

        setIsLoading(false);

        if (result.errors && result.errors.length > 0) {
            errorAlert(result.errors[0]);
        } else {
            successAlert("Callback has been processed");
        }
    }

    const getWidgetCountries = async () => {
        const w_id = await getWidgetId();
        var _sources = await getCountryList(
          `widget/get-widget-countries?widget_id=${w_id}`
        );
        _sources = await _sources.json();
        resetWidgetCountries(_sources?.data?.allowed_countries);
      };
    
      const resetWidgetCountries = (countries) => {
        const allCountry = CountryData;
        const allowed = allCountry
          .filter((item) => {
            if (countries?.includes(item.name)) {
              return item;
            }
          })
          .map((item) => item.code.toLowerCase());
        setAllowedCountries(allowed);
      };

    return (
        <>
            <h5>Callback (#{lead ? lead.id : ""})</h5>
            <p className="text-lg">First, we'll call your phone. Once you answer, we'll call the customer's phone. From their perspective, it's like you called directly from your tracking number.</p>


            <h6 className="text-lg">Team member</h6>
            {teamMembers && <select className="w-full px-2 py-1 text-gray-700" onChange={(e) => { setTeamMember(e.target.value) }} value={teamMember ? teamMember.id : ""} id="team_member_selector">
                {teamMembers && teamMembers.map((_teamMember) => (
                    <option key={_teamMember.id} value={_teamMember.id}>{`${_teamMember.first_name} ${_teamMember.last_name}`}</option>
                ))}
            </select>}

            <h6 className="mt-4 text-lg">Customer's phone number</h6>
            {/* <input onChange={(e) => { setPhoneNumber(e.target.value) }} value={phoneNumber} type="text" placeholder="+10000000000" className="w-full px-2 py-1 text-gray-700" id="callback_phone_number" /> */}
            {allowedCountries && allowedCountries?.length ? (
            <PhoneInput
                international
                value={phoneNumber}
                onlyCountries={allowedCountries}
                country={"us"}
                enableSearch={true}
                // disabled={inProgress}
                onChange={(e, data) => {setPhoneNumber(e)}}
            />
            ) : null}

            <div className="mt-3 mb-2">
                <button disabled={isLoading} onClick={callback} id="callback-lead" className="btn-main mt-2 !text-lg">Proceed</button>
            </div>
        </>
    );
}