import React, { useEffect, useState } from 'react'
import { get, post } from '../helpers/network';
import userGreen from "../images/user-green.png";
import userRed from "../images/user-red.png";
import Switch from 'react-switch';
import { Tooltip } from 'react-tooltip';

const UserSettings = () => {
    
    const [teamMembers, setTeamMembers] = useState([]);


    const getTeamMembers = async () => {
        var members = await get("team/members?with_non_active=1");
        members = await members.json();
        setTeamMembers(members.data.members);
    };
    const updateMemberAvaibility = async (id, status) => {
        await post(`team/update-member-availability/${id}/${status}`);

        getTeamMembers()
    };

    useEffect(() => {
        getTeamMembers();
    }, []);
  return (
    <div>
          {teamMembers?.map((obj,ind)=>
          
              <div className="call-routing" key={ind}>
                      <h6 className='d-flex '>{obj?.verified ? <img src={userGreen} id="verified" alt="green" width={20} style={{ marginRight: '2px', height: '20px' }} data-tooltip-content='Verified'/> : <img id='not-verified' style={{ marginRight: '2px', height: '20px' }}  width={20}  src={userRed} alt="red" />} {obj?.first_name} {obj?.last_name}</h6>
              

              <div className="custom-control custom-switch" style={{display:'grid'}}>
                
                          <Switch  uncheckedIcon={false} checkedIcon={false}  checked={obj?.status} onChange={() => updateMemberAvaibility(obj?.id, obj?.status ? 0 : 1)}  />
                     
                  <span style={{color:'#fff'}}>{obj?.status ? 'Online' : 'Offline'}</span>
              </div>
          </div>

          )}
          <Tooltip anchorId="verified" />
          <Tooltip anchorId="not-verified" />

    </div>
  )
}

export default UserSettings