import { useEffect } from "react";
import { classNames } from "../helpers/utilities";

export default function FullscreenModal({ component, showTopNav, customeClass }) {

    useEffect(()=>{
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden";

        return function cleanup(){
            document.body.style.overflow = "scroll";
        }
    }, []);


    return (
        <>
            <div id="fullscreen-modal" className={classNames(showTopNav ? "mt-16":"", `${customeClass} overflow-scroll fixed !w-auto h-screen top-0 z-50`)} style={{backgroundColor: "#262b3f"}}>
                {component}
            </div>
        </>
    )
}