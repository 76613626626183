import { useState } from "react";
import TimezoneSelect from "react-timezone-select";
import { errorAlert, post, successAlert } from "../helpers/network";

export default function ProfileSettings({ }) {

    const [firstName, setFirstName] = useState(window.user.first_name);
    const [lastName, setLastName] = useState(window.user.last_name);
    const [phoneNumber, setPhoneNumber] = useState(window.user.phone_number);
    const [selectedTimezone, setSelectedTimezone] = useState(window.user.time_zone)

    const saveProfile = async (e) => {
        e.preventDefault();

        var payload = new FormData();
        payload.append("full_name", `${firstName} ${lastName}`);
        payload.append("time_zone", selectedTimezone.value);

        if (phoneNumber) {
            payload.append("official_phone_number", phoneNumber);
        }

        var result = await post("user/update-profile-api", payload);

        if (result.errors && result.errors.length > 0) {
            errorAlert(result.errors[0]);
        } else {
            successAlert("Profile updated!");
        }
    }

    return (
        <div className="settings-list">
            <form onSubmit={saveProfile}>
                <div className="item hours">
                    <h2>Account Settings</h2>
                    <div className="hours-list">
                        <div>
                            <h3>
                                First name
                            </h3>
                            <div className="block  mt-2">
                                <input placeholder="First name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} type="text" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="hours-list mt-4">
                        <div>
                            <h3>
                                Last name
                            </h3>
                            <div className="block mt-2">
                                <input placeholder="Last name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} type="text" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="hours-list mt-4">
                        <div>
                            <h3>
                                Emaill address
                            </h3>
                            <div className="block mt-2">
                                <input value={window.user.email} disabled type="text" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="hours-list mt-4">
                        <div>
                            <h3>
                                Phone number
                            </h3>
                            <div className="block mt-2">
                                <input placeholder="Phone number" value={phoneNumber ? phoneNumber : "+"} onChange={(e) => { setPhoneNumber(e.target.value) }} type="text" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="hours-list mt-4">
                        <div>
                            <h3>
                                Timezone
                            </h3>
                            <div className="block mt-2">
                                <TimezoneSelect
                                    value={selectedTimezone}
                                    onChange={setSelectedTimezone}
                                />
                            </div>
                        </div>
                    </div>
                    <input type="submit" className="mt-4 text-center btn-main" value="Save" />
                </div>
            </form>
        </div>
    );

}