import moment from "moment";
import { getMessageBody } from "../helpers/messages";
import SingleTick from "./SingleTick";
let date1 = moment().format('YYYY-MM-DD')
export default function ChatMessage({ message, conversation, }) {
  
  const setCreatedDate = data => {
   
    if (moment(data?.created_at).format('YYYY-MM-DD') !== date1) {
        
      date1 = moment(data?.created_at).format('YYYY-MM-DD')
      return true
    } else {
      return false
    }
  }
  const getFormat = date => {
    if (
      moment(date).format('dddd, MMMM Do YYYY') ===
      moment().format('dddd, MMMM Do YYYY')
    )
      return 'Today'
    else if (
      moment(date).format('dddd, MMMM Do YYYY') ===
      moment()
        .subtract(1, 'days')
        .format('dddd, MMMM Do YYYY')
    ) {
      return 'Yesterday'
    } else {
      return moment(date).format('LL')
    }
  }
  return (
    <>
    <p style={{color:'#fff', textAlign:'center'}}>
      
      { setCreatedDate(message)
        ? getFormat(message?.created_at)
        : ' '}
    </p>
      {!message?.conversation_id && message?.note ? (
        <>
        {console.log('message', message)}
     
          <div
            className={
              "chat-" +
              message.message_type +
              " container-" +
              message.direction +
              " flex gap-2 items-center"
            }
          >
            <div
              className={"msg-" + "note" + " message item conversation-item"}
              data-id="13233"
              data-index="0"
            >
              {<p className="text-white my-3 break-all">{message?.note}</p>}

              {message.message_type == "Message" && (
                <div className="flex lead-nav" id="{id}">
                  <div className="list w-full time">
                    <SingleTick status={message.status} />
                    <div className="list-item">
                      {
                         moment(moment(message?.created_at).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD')) ?

                          moment(message.created_at).fromNow():
                      message?.created_at
                        ? moment(
                          message?.created_at,
                          'HH:mm:ss'
                        ).format('hh:mm A')
                        : moment(message?.created_at).format('LT')}
                      {/* {moment(message.created_at).fromNow()} */}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <img
              title={conversation?.owner_name}
              className="h-8 w-8 owner-img"
              src="https://ui-avatars.com/api/?name=undefined&rounded=true"
              alt=""
            />
          </div>
        </>
      ) : (
        <>
          {message.message_type != "Log" && (
            <div
              className={
                "chat-" +
                message.message_type +
                " container-" +
                message.direction +
                " flex gap-2 items-center"
              }
            >
              <div
                className={
                  "msg-" + message.direction + " message item conversation-item"
                }
                data-id="13233"
                data-index="0"
              >
                  {<p className="text-white my-3 break-all">{getMessageBody(message)}</p>}

                {message.message_type == "Message" && (
                  <div className="flex lead-nav" id="{id}">
                                        <div className="list w-full time">
                      <SingleTick status={message.status} />
                      <div className="list-item">
                        {/* {moment(message.created_at).fromNow()} */}
                          {moment(moment(message?.created_at).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD')) ?
                            moment(message.created_at).fromNow() :  message?.created_at
                            ? moment(
                              message?.created_at,
                              'HH:mm:ss'
                            ).format('hh:mm A')
                            : moment(message?.created_at).format('LT')}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <img
                title={conversation?.owner_name}
                className="h-8 w-8 owner-img"
                src="https://ui-avatars.com/api/?name=undefined&rounded=true"
              />
            </div>
          )}
          {message.message_type == "Log" && (
            <div className="text-white mt-1 mb-1 text-sm">
              {`[${moment(message.created_at).fromNow()}]`}{" "}
              {getMessageBody(message)}
            </div>
          )}
        </>
      )}
    </>
  );
}
