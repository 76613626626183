import { useState } from "react";
import GoogleButton from "../components/GoogleButton";
import {
  appClientID,
  appClientSecret,
  errorAlert,
  post,
  setAccessToken
} from "../helpers/network";
import { Link, useNavigate } from "react-router-dom";
import DownModel from "../components/DownModel";
import SwipeUpModal from "../components/SwipeUpModal";
import ResetPasswordModel from "../components/ResetPasswordModel";

export default function Login() {
  const navigate=useNavigate()
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
const [openModal, setOpenModal] = useState(false)
  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    var formdata = new FormData();
    formdata.append("client_id", appClientID);
    formdata.append("client_secret", appClientSecret);
    formdata.append("grant_type", "password");
    formdata.append("email", email);
    formdata.append("password", password);

    const result = await post("oauth/token", formdata);
    const resultJson = await result.json();
    if (resultJson.data.access_token) {
      setAccessToken(resultJson.data.access_token);
      // window.location.reload();
      window.location.href = "/";

    } else {
      errorAlert("Login failed");
    }
    setIsLoading(false);
  };

  return (
    <>
    <section className="login h-full flex flex-col" >
      <div className="logo">
        <a href="#/">
          <img src="images/logo-limecall.svg" alt="Logo LimeCall" />
        </a>
      </div>
      <h1>Sign in to your account</h1>
      <div className="flex w-full align-center justify-center" style={{borderRadius:'33px'}}>
      <GoogleButton loading={setIsLoading}  isLoading={isLoading} content="Sign in with Google" />
      </div>
      <div className="or mb-4">
      </div>{' '}
      <form onSubmit={onLogin} id="login_form">
        <label>
          E-mail addresss:
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id="login_email"
            type="email"
          />
        </label>
        <label>
          Password:
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            id="login_password"
            type="password"
          />
        </label>
        <div className="forgot">
          <a onClick={()=>setOpenModal(true)}>Forgot your password?</a>
        </div>
        <button disabled={isLoading} type="submit" className="btn-main">
          Sign In
        </button>
          {/* <button onClick={() => navigate('/signup')} type="submit" className="btn-main">
          Sign Up
        </button> */}
      </form>
      <button className="btn-main mt-auto !w-fit p-3 mx-auto" onClick={() => window.open('https://platform.limecall.com/')}>
           Login to Desktop Panel
        </button>
    </section>
      {openModal && (
        <SwipeUpModal
          component={
            <ResetPasswordModel onClosed={() => {
              setOpenModal(false);
            }}  />
          }
          onClosed={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}
