import moment from 'moment';

export function getStatFromDate(rangeForHumans) {
    var currentDate = moment();

    switch (rangeForHumans) {
        case "Today":
            return currentDate.format("YYYY-MM-DD");
        case "Yesterday":
            return currentDate.subtract(1, "day").format("YYYY-MM-DD");
        case "This week":
            return currentDate.startOf("isoWeek").format("YYYY-MM-DD");
        case "Last week":
            return currentDate.subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD");
        case "This month":
            return currentDate.startOf("month").format("YYYY-MM-DD");
        case "Last month":
            return currentDate.subtract(1, "month").startOf("month").format("YYYY-MM-DD");
        default:
            return "none";
    }
}

export function getStatToDate(rangeForHumans) {
    var currentDate = moment();

    switch (rangeForHumans) {
        case "Today":
            return currentDate.format("YYYY-MM-DD");
        case "Yesterday":
            return currentDate.subtract(1, "day").format("YYYY-MM-DD");
        case "This week":
            return currentDate.endOf("isoWeek").format("YYYY-MM-DD");
        case "Last week":
            return currentDate.subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD");
        case "This month":
            return currentDate.endOf("month").format("YYYY-MM-DD");
        case "Last month":
            return currentDate.subtract(1, "month").endOf("month").format("YYYY-MM-DD");
        default:
            return "none";
    }
}