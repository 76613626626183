import axios from "axios";
import React from "react";
import GoogleLogin from "react-google-login";
import { deploymentType } from "../helpers/globals";
import { errorAlert, setAccessToken } from "../helpers/network";

const GoogleButton = (props) => {
  const { content } = props;
  const clientId =
    process.env.REACT_APP_GLOGIN_CLIENT_ID ||
    "561194678958-bpdro47f9k0vub52o06rsiisqbqt2n8i.apps.googleusercontent.com";
  const type = deploymentType();
  const googleLogin = async (response) => {
    const base =
      type === "development"
        ? "https://qa.limecall.com"
        : "https://app.limecall.com";
    const url = `${base}/api/social-login`;
    const data = {
      email: response.profileObj.email,
      name: response.profileObj.givenName + "" + response.profileObj.familyName,
      provider_return_id: response.accessToken,
      social_type: 1,
    };
    props.loading(true);
    axios
      .post(url, data)
      .then((res) => {
        props.loading(false);
        if (res?.data?.data?.access_token) {
          setAccessToken(res?.data?.data?.access_token);
          localStorage.setItem(
            "phone_numbers",
            JSON.stringify(res.data.data.phone_numbers)
          );
          localStorage.setItem("access_token", res.data.data.access_token);
          localStorage.setItem(
            "working_hours_status",
            res.data.data.working_hours_status
          );
          localStorage.setItem("widget_id", res.data.data.widget_id);
          localStorage.setItem(
            "mobile_verification_status",
            res.data.data.mobile_verification_status
          );
          localStorage.setItem("plan_name", res.data.data.plan_name);
          localStorage.setItem("signed_up_at", res.data.data.signed_up_at);
          localStorage.setItem("status", res.data.data.status);

          localStorage.setItem("role_name", res.data.data.role.name);

          localStorage.setItem("first_name", res.data.data.first_name);
          localStorage.setItem(
            "full_name",
            res.data.data.first_name + res.data.data.last_name
          );
          localStorage.setItem("last_name", res.data.data.last_name);
          localStorage.setItem(
            "skip_onboarding",
            res.data.data.skip_onboarding
          );
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("id", res.data.data.id);
          localStorage.setItem("email_verification_status", "1");
          localStorage.setItem(
            "admin_verification_status",
            res.data.data.admin_verification_status
          );

          if (res.data.data.account_status) {
            localStorage.setItem("account_status", "1");
          } else {
            localStorage.setItem("account_status", "0");
          }
          window.location.href = "/";
          // window.location.reload();
          return;
        }
      })
      .catch(async (error) => {
        props?.loading(false);
        const errors = await { ...error };
        if (errors?.response && errors?.response?.data?.errors) {
          errorAlert(errors?.response?.data?.errors?.[0]);
        } else {
          errorAlert("Some thing went to wrong!");
        }
      });
  };

  const googleLoginFailed = (response) => {
  };

  return (
    <GoogleLogin
      clientId={clientId}
      // render={(renderProps) => (
      //   <button
      //     onClick={renderProps.onClick}
      //     type="button"
      //     className="btn-main"
      //     disabled={props?.isLoading}
      //   >
      //     {content}
      //   </button>
      // )}
      className="mb-4 google-button"
      style={{borderRadius:'33px',}}
      onSuccess={googleLogin}
      onFailure={googleLoginFailed}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default GoogleButton;
