import React, { useEffect, useState } from "react";
import { getWidgetId } from "../helpers/globals";
import { errorAlert, get, postJson, successAlert } from "../helpers/network";

export default function CallRouting() {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    getCallForwadingSettings();
  }, []);

  const handleUpdateStatus = async (e, index) => {
    if (info[index]?.phone_number) {
      const payload = {
        phone_number: info[index]?.phone_number,
        status: e?.target?.checked ? 1 : 0,
      };
      var result = await postJson(
        "user/change-number-status",
        JSON.stringify(payload)
      );
      result = await result.json();
      if (result?.data) {
        successAlert("Forward All Calls Status Updated");
        getCallForwadingSettings();
      }
    } else {
      errorAlert("Please Add Call Forwarding Number First");
    }
  };

  const getCallForwadingSettings = async () => {
    const w_id = await getWidgetId();
    let result = await get(`widget/get-widget-call-settings?widget_id=${w_id}`);
    result = await result.json();
    if (
      result?.data?.available_forward_no &&
      result?.data?.available_forward_no?.length
    ) {
      setInfo(result?.data?.available_forward_no || []);
    } else {
      setInfo([]);
    }
  };

  return (
    <>
      <div className="call-routing-wrap">
        <div className="call-routing">
          <h6>Do not Disturb</h6>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitch"
            />
            <label
              className="custom-control-label"
              htmlFor="customSwitch"
            ></label>
          </div>
        </div>
      </div>

      {/* <div className="call-routing-wrap">
        {info && info?.length > 0 ? (
          info.map((list, index) => {
            return (
              <div className="call-routing-wrap" key={index}>
                {index === 0 && (
                  <p className="">
                    Forward to External number (if toggle on show the number and
                    additional toggle enable or disable)
                  </p>
                )}
                <div className="call-routing">
                  <h6>{list?.phone_number}</h6>
                  <div className="custom-control custom-switch text-white">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`customSwitchForward${index}`}
                      checked={+list?.active === 1 ? true : false}
                      onChange={(e) => handleUpdateStatus(e, index)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`customSwitchForward${index}`}
                    ></label>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center no-active-call">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
            </svg>
            <h6>Call Forwading Numbers Are Not Available Yet.</h6>
          </div>
        )}
      </div> */}
    </>
  );
}
