import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../helpers/network";

export function AudioSettings({ }) {

    const [audioInputDevices, setAudioInputDevices] = useState(null);
    const [audioOutputDevices, setAudioOutputDevices] = useState(null);
    
    const [outputDevice, setOutputDevice] = useState(getCookie("outputDevice"));
    const [inputDevice, setInputDevice] = useState(getCookie("inputDevice"));
    const [ringtoneDevice, setRingtoneDevice] = useState(getCookie("ringtoneDevice"));


    useEffect(() => {

        var inputs = [];
        var outputs = [];

        navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                devices.forEach((device) => {
                    if (device.kind == "audioinput") {
                        inputs.push(device);
                    }
                    else if (device.kind == "audiooutput") {
                        outputs.push(device);
                    }
                });

                setAudioInputDevices(inputs);
                setAudioOutputDevices(outputs);

            })
            .catch((err) => {
                console.error(`${err.name}: ${err.message}`);
            });

    }, []);


    useEffect(()=>{ 
        if(outputDevice != "null"){
            setCookie("outputDevice", outputDevice);
        }
    }, [outputDevice]);

    useEffect(()=>{ 
        if(inputDevice != "null"){
            setCookie("inputDevice", inputDevice);
        }
    }, [inputDevice]);

    useEffect(()=>{ 
        if(ringtoneDevice != "null"){
            setCookie("ringtoneDevice", ringtoneDevice);
        }
    }, [ringtoneDevice]);

    return (
        <div className="settings-list">
            <div className="item hours">
                <h2>Audio Settings</h2>
                <div className="hours-list">
                    <div>
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                            </svg>
                            Output Device
                        </h3>
                        <div className="block mt-2">
                            <select value={ outputDevice ? outputDevice : "" } onChange={(e)=>{ setOutputDevice(e.target.value) }} className="w-full block p-2">
                                {audioOutputDevices && audioOutputDevices.map((device, index) => (
                                    <option key={index} value={device.deviceId}>{device.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-3">
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                            </svg>
                            Input Device
                        </h3>
                        <div className="block mt-2">
                            <select value={ inputDevice ? inputDevice : "" } onChange={(e)=>{ setInputDevice(e.target.value) }} className="w-full block p-2">
                                {audioInputDevices && audioInputDevices.map((device, index) => (
                                    <option key={index} value={device.deviceId}>{device.label}</option>
                                ))}                            </select>
                        </div>
                    </div>
                    <div className="mt-3">
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                            </svg>
                            Ringtone Device
                        </h3>
                        <div className="block mt-2">
                            <select value={ ringtoneDevice ? ringtoneDevice : "" } onChange={(e)=>{ setRingtoneDevice(e.target.value) }} className="w-full block p-2">
                                {audioOutputDevices && audioOutputDevices.map((device, index) => (
                                    <option key={index} value={device.deviceId}>{device.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}