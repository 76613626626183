import moment from "moment";
import React, { useEffect, useState } from "react";
import { get } from "../helpers/network";
import { hideLoader, showLoader } from "../helpers/utilities";
import scheduleIcon from "../images/schedule-icon.svg";
import {  useNavigate } from "react-router";

export default function HomeSchedule() {
  const [activeButton, setActiveButton] = useState("upcomming");
  const [initalCall, setInitalCall] = useState(true);
  const navigate=useNavigate()
  const [data, setData] = useState({
    past: [],
    upcomming: [],
    cancel: [],
    meetingTime: 0,
  });

  //Effects
  useEffect(() => {
    if (!initalCall) {
      switch (activeButton) {
        case "upcomming":
          if (!data["upcomming"]?.length) {
            getSchuduleCall();
          }
          break;

        case "past":
          if (!data["past"]?.length) {
            getSchuduleCall();
          }
          break;

        case "cancel":
          if (!data["cancel"]?.length) {
            getSchuduleCall();
          }
          break;

        default:
          if (!data["upcomming"]?.length) {
            getSchuduleCall();
          }
          break;
      }
    }
  }, [activeButton]);

  //Effects
  useEffect(() => {
    getSchuduleCall();
    return () => {
      hideLoader();
    };
  }, []);

  const DynamicMessage = (flag) => {
    switch (activeButton) {
      case "upcomming":
        return flag === true
          ? "This is where you will find your active scheduled calls."
          : "No Active Scheduled Calls";

      case "past":
        return flag === true
          ? "This is where you will find your past scheduled calls."
          : "No Past Scheduled Calls";

      case "cancel":
        return flag === true
          ? "This is where you will find your cancelled scheduled calls."
          : "No cancelled calls";

      default:
        return flag === true
          ? "This is where you will find your active scheduled calls."
          : "No Active Scheduled Calls";
    }
  };

  //Methods
  const getSchuduleCall = async () => {
    initalCall && setInitalCall(false);
    let URL = `leads?sort=-id&filter[agent][is]=${
      window?.user?.first_name + " " + window?.user?.last_name
    }&page=1`;
    switch (activeButton) {
      case "upcomming":
        URL =
          URL +
          `&filter[type][is]=SCHEDULE_CALL&filter[schedule_call_status][in]=scheduled,rescheduled`;
        break;

      case "past":
        URL =
          URL +
          `&filter[schedule_call_status][!is]=canceled&filter[type][in]=SCHEDULE_CALL&filter[request_call_time][lt]=${new Date()?.toISOString()}
        `;
        break;

      case "cancel":
        URL =
          URL +
          `&filter[type][in]=SCHEDULE_CALL&filter[schedule_call_status][is]=canceled`;
        break;

      default:
        URL =
          URL +
          `&filter[type][in]=SCHEDULE_CALL&filter[final_status][is]=scheduled,rescheduled`;
        break;
    }
    showLoader();
    const res = await get(URL);
    const resJson = await res.json();
    if (resJson?.data?.leads?.data) {
      hideLoader();
      switch (activeButton) {
        case "upcomming":
          setData({
            ...data,
            upcomming: resJson?.data?.leads?.data || [],
            meetingTime: resJson?.data?.meeting_duration || 0,
          });
          break;

        case "past":
          setData({
            ...data,
            past: resJson?.data?.leads?.data || [],
            meetingTime: resJson?.data?.meeting_duration || 0,
          });
          break;

        case "cancel":
          setData({
            ...data,
            cancel: resJson?.data?.leads?.data || [],
            meetingTime: resJson?.data?.meeting_duration || 0,
          });
          break;

        default:
          setData({
            ...data,
            upcomming: resJson?.data?.leads?.data || [],
            meetingTime: resJson?.data?.meeting_duration || 0,
          });
          break;
      }
    } else {
      hideLoader();
    }
  };

  const formatTime = "hh:mm A";

  return (
    <div className="text-white px-2 tab-schedule">
      <div className="item text-center">
        <h2>Schedule</h2>
      </div>
      <div className="d-flex justify-content-around tab-button">
        <button
          className={activeButton === "upcomming" ? "active" : ""}
          onClick={() => setActiveButton("upcomming")}
        >
          Upcoming
        </button>
        <button
          className={activeButton === "past" ? "active" : ""}
          onClick={() => setActiveButton("past")}
        >
          Past
        </button>
        <button
          className={activeButton === "cancel" ? "active" : ""}
          onClick={() => setActiveButton("cancel")}
        >
          Canceled
        </button>
      </div>
      <div className="settings-list relative mb-2 mt-3">
        <ul className="list-schedule">
          {data?.[activeButton] && data?.[activeButton]?.length > 0 ? (
            data?.[activeButton]?.slice(0, 3)?.map((list, index) => {
              return (
                <li onClick={()=> activeButton ==="upcomming" ? navigate("/leads",{state:list}):null} className="schedule-list mb-2 px-3" key={index}>
                  <span>
                    {list?.request_call_time ? (
                      <>
                        {moment(list?.request_call_time).format("DD MMM YYYY")}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}

                    {list?.request_call_time
                      ? `${moment(list?.request_call_time).format(
                          formatTime
                        )} - ${moment(list?.request_call_time)
                          .add(+data?.meetingTime, "minutes")
                          .format(formatTime)}`
                      : moment().format(formatTime) -
                        moment()
                          .add(+data?.meetingTime, "minutes")
                          .format(formatTime)}
                  </span>
                  <span>
                    {list?.agent || "-"}
                    <br></br>
                    <span>{list?.phone_number || "-"}</span>
                  </span>
                </li>
              );
            })
          ) : (
            <div className="text-center no-active-call">
              <img src={scheduleIcon} />
              <h6>{DynamicMessage()} </h6>
              <p>{DynamicMessage(true)}</p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}
