
import {
  BackspaceIcon,
  MicrophoneIcon,
  PhoneIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import Pusher from "pusher-js";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useLocation, useNavigate, useParams } from "react-router";
import FullscreenModal from "../components/FullscreenModal";
import SourceSelector from "../components/SourceSelector";
import CountryData from "../helpers/data/countryCode.json";
import { deploymentType, getWidgetId } from "../helpers/globals";
import {
  errorAlert,
  get,
  getCountryList,
  post,
  postJson,
} from "../helpers/network";
import { hideLoader } from "../helpers/utilities";
import { ReactComponent as DialPad } from "../images/dialpad.svg";
import { ReactComponent as Close } from "../images/close.svg";

let timerInterval = null,
  connection = null,
  device = null,
  timerData = 0,
  leadId = "",
  callToken = 0,
  isCall = false,
  callerData = "";
export function Dialer(props) {
  const location = useLocation()
  const navigate=useNavigate()
  let token = window?.apiToken || localStorage.getItem("access_token");
  let stateNumber = useLocation();
  const params=useParams()
  const [targetNumber, setTargetNumber] = useState("");
  const [keyTargetNumber, setKeyTargetNumber] = useState("");
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [credits, setCredits] = useState(0);
  const [code, setCode] = useState("+1");
  const [allowedCountries, setAllowedCountries] = useState([]);
  const [callInitiate, setCallInitiate] = useState(false);
  const [micBlocked, setMicBlocked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [disabledCall, setDisabledCall] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [timer, setTimer] = useState(0);
  const [speaker, setSpeaker] = useState(false);
  const [keypad, setKeypad] = useState(false);
  const [callRates, setCallRates] = useState("");
  const [userTime, setUserTime] = useState(null);
  const [showfeedBack, setShowfeedBack] = useState(false);
  const [showInCommingCall, setShowInCommingCall] = useState(false);
  // const [showInCommingCall, setShowInCommingCall] = useState(true);
  const [callInfo, setCallInfo] = useState({
    status: "start",
    displayIncoming: false,
    callStatus: "idle",
    currentCallData: {},
    timerData: 0,
    displayAdditionalTools: null,
    callMuteStatus: false,
    systemMic: false,
    outboundCall: {
      error: false,
      connected: false,
      ringing: true,
      disconnected: false,
      intiate: false,
      leadId: 0,
      errmsg: "",
      msg: "",
    },
  });

  // console.log("device", device);
  // console.log("callerData", callerData);
  // console.log("callInfo 1256 :>> ", callInfo);

  useEffect(() => {
    if (stateNumber?.state?.number) {
      setTimeout(() => {
        setTargetNumber(stateNumber?.state?.number);
      }, 2000);
    }
  }, [stateNumber]);

  useEffect(() => {
    if (!keypad && keyTargetNumber) {
      setKeyTargetNumber("");
    }
  }, [keypad]);

  useEffect(() => {
    do_load();
    setupDevice();
    return () => {
      hideLoader();
    };
  }, []);

  useEffect(() => {
    getUserNumbers();
    getCredits();
    getWidgetCountries();
  }, []);

  useEffect(() => {
    const type = deploymentType();
    const id =
      type === "development"
        ? process.env.REACT_APP_PUSHER_ID || "7b262bf6c81fabf9536b"
        : process.env.REACT_APP_PUSHER_ID || "06732b853776c74d2631";
    window.pusher = new Pusher(id, {
      cluster: "eu",
      forceTLS: false,
      disableStats: true,
    });

    window.pusher.connection.bind("error", function (err) {
      // if( err.error.data.code === 4004 ) {
      console.log("Over limit!", err);
      // }
    });
  }, []);

  const exitCall = () => {
    isCall = false;
    setKeypad(false);
    setTargetNumber("+1");
    timerData = 0;
    setCallInitiate(false);
    rejectCall(true);
    setSpeaker(false);
    setKeypad(false);
    setCallInfo({
      ...callInfo,
      callMuteStatus: false,
    });
    callToken = 0;
  };

  const setupDevice = () => {
    setTargetNumber("+1");
    setCallInitiate(false);

    if (!micBlocked) {
      setErrorMessage("");
      setMessage("");
      setDisabledCall(false);
    }
    requestTokenForOutbound();
  };

  const requestTokenForOutbound = async () => {
    var tokensData = await get("generate-token-for-outbound-calls");
    var result = await tokensData.json();
    if (result?.data?.token) {
      if (!isCall) {
        runOutboundDialer(result?.data?.token);
      }
      // runOutboundDialer(result?.data?.token);
    } else {
      // errorAlert(result?.errors?.[0] || "Something went to wrong!");
    }
  };

  const do_load = () => {
    const my_script = new_script(
      "https://media.twiliocdn.com/sdk/js/client/v1.13/twilio.min.js"
    );
    my_script
      .then(function () {
        setCallInfo({
          ...callInfo,
          status: "done",
        });
      })
      .catch(function () {
        setCallInfo({
          ...callInfo,
          status: "error",
        });
      });
  };

  const new_script = (src) => {
    return new Promise(function (resolve, reject) {
      var script = document.createElement("script");
      script.src = src;
      script.addEventListener("load", function () {
        resolve();
      });
      script.addEventListener("error", function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  };

  const getWidgetCountries = async () => {
    const w_id = await getWidgetId();
    var _sources = await getCountryList(
      `widget/get-widget-countries?widget_id=${w_id}`
    );
    _sources = await _sources.json();
    resetWidgetCountries(_sources?.data?.allowed_countries);
  };

  const getCredits = async () => {
    var subscription = await get("subscription");
    var result = await subscription.json();

    setCredits(result?.data?.credits_in_dollars);
  };

  const makeCall = async () => {
    if (targetNumber?.length < 10) {
      errorAlert("Please enter valid number first");
      return;
    }
    try {
      setInProgress(true);
      var payload = new FormData();
      payload.append("phone_number", "+" + targetNumber);
      payload.append("country_code", code);
      payload.append("from", selectedSource);
      setCallInitiate(true);
      var result = await post("customer-browser-calls-create-call", payload);
      result = await result.json();
      setInProgress(false);
      if (result.errors && result.errors.length > 0) {
        // errorAlert(result.errors[0]);
        setCallInitiate(false);
      } else {
        // successAlert("Callback has been processed");
        if (result) {
          var callId = result?.data.call_id;
          leadId = result?.data.call_id;
          var phoneNumberBE = result?.data?.phone_number;
          setCallRates(result?.data?.price_per_minute);
          setUserTime(result?.data?.customer_local_time);
          if (!callId) {
            errorAlert("Cant initiate call, cant fetch lead detail");
            return;
          }
          setCallData(callId);
          var params = {
            To: phoneNumberBE,
            callId: callId,
          };
          // console.log({params})
          await requestOutboundCall(params);
        }
      }
    } catch (error) {
      setCallInitiate(false);
      setInProgress(false);
      errorAlert("Cant initiate call, cant fetch lead detail");
    }
  };

  const requestOutboundCall = async (param) => {
    //device.current.connect(param)
    return await window?.Twilio?.Device?.connect(param);
  };

  const runOutboundDialer = (tkn) => {
    window.Twilio.Device.setup(tkn, {
      codecPreferences: ["opus", "pcmu"],
      fakeLocalDTMF: true,
      enableRingingState: true,
    });

    window.Twilio.Device.on("ready", (device) => {
      // console.log("device connected", device)
    });

    window.Twilio.Device.on("error", (error) => {
      console.log("error", error);
    });
    window.Twilio.Device.on("disconnect", (conn) => {
      rejectCall(true);
      if (leadId) {
        setShowfeedBack(true);
      }
      // console.log("disconnect", conn)
    });

    window.Twilio.Device.on("cancel", (conn) => {
      if (!isCall) {
        rejectCall();
      }
      // console.log("cancel'", conn)
    });

    window.Twilio.Device.on("connect", (conn) => {
      setCallInfo({
        ...callInfo,
        callStatus: "connected",
      });

      connection = conn;
      // console.log("connection ", conn)
    });
  };

  const setCallData = async (leadId) => {
    window.channel = window.pusher.subscribe(`call-channel-${leadId}`);
    window.channel.bind("change-call-status", updateEvents);

    var response = await get(`leads/get-call-by-id?id=${leadId}`);
    var result = await response.json();
    if (result?.data) {
      setCallInfo({
        ...callInfo,
        currentCallData: result?.data,
      });
      setCallStatus("leadId", leadId);
    } else {
      // errorAlert(result?.errors?.[0] || "Something went to wrong!");
    }
  };

  const setCallStatus = (key, value) => {
    const temp = { ...callInfo?.outboundCall };
    temp[key] = value;
    setCallInfo({
      ...callInfo,
      outboundCall: temp,
    });
  };

  const updateEvents = (data) => {
    // customer picked the call
    if (
      data.agent_status == "agent_connected" &&
      data.customer_status == "initiated"
    ) {
      setCallStatus("ringing", true);
    }

    if (
      data.agent_status == "agent_connected" &&
      data.customer_status == "customer_connected"
    ) {
      setCallStatus("connected", true);
      setCallInfo({
        ...callInfo,
        displayIncoming: true,
        callStatus: "connected",
      });
      startTimeRecording();
    }

    if (data.customer_status == "Failed busy") {
      setCallStatus("disconnected", true);
      rejectCall(true);
      if (leadId) {
        setShowfeedBack(true);
      }
    }
  };

  const dialNumber = (num) => {
    if (inProgress) {
      return;
    }
    var number = `${targetNumber}${num}`;
    setTargetNumber(number);
  };

  const keyDialNumber = async (num) => {
    var number = `${keyTargetNumber}${num}`;
    setKeyTargetNumber(number);
    if (connection) {
      window?.Twilio?.Device?.activeConnection()?.sendDigits(num?.toString());
    }
  };

  const getUserNumbers = async () => {
    var _sources = await get("user/caller-id-with-names");
    _sources = await _sources.json();
    if (_sources?.data && _sources?.data?.length) {
      const data = [];
      const datas = _sources?.data?.map((number, i) => {
        if (i === 0) {
          setSelectedSource(number?.number || number?.phone_number);
        }
        data.push({
          key: number?.name,
          value: number?.number || number?.phone_number,
          text: `${number?.name?.replace("Number", "")} (${
            number?.number || number?.phone_number
          })`,
        });
      });
      setSources(data);
    }
  };

  const onBackspace = () => {
    if (!keypad || !callInitiate) {
      setTargetNumber(targetNumber.slice(0, -1));
    } else {
      setKeyTargetNumber(keyTargetNumber.slice(0, -1));
    }
  };

  const resetWidgetCountries = (countries) => {
    const allCountry = CountryData;
    const allowed = allCountry
      .filter((item) => {
        if (countries?.includes(item.name)) {
          return item;
        }
      })
      .map((item) => item.code.toLowerCase());
    setAllowedCountries(allowed);
  };

  const renderDialer = (token) => {
    if (callInfo?.status === "done") {
      callToken = callToken + 1;
      if (callToken < 20) {
        setupDialer(token);
      }
    }
  };

  useEffect(() => {
    if(params?.any){
      setTargetNumber(params?.any?.replace('number=',''))
    }
  }, [params?.any])
  

  const setupDialer = (token) => {
    if (token === null || token === "" || !token) {
      return null;
    }
    let twilioToken = fetchToken(token);
    if (twilioToken === "") {
      return null;
    }
    return null;
  };

  const fetchToken = async (token) => {
    var tkn = "";
    var result = await post("customer-generate-call-token", null);
    result = await result.json();
    if (result?.data) {
      tkn = result?.data?.token;
      tkn && runDialer(tkn);
    }
    return tkn;
  };

  const runDialer = (tkn) => {
    const twilio = window.Twilio;
    if (!device) {
      device = new twilio.Device();
      device.setup(tkn, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });
    } else {
      return null;
    }
    device.on("ready", (device) => {
      // console.log("connected call", device)
    });

    device.on("error", (error) => {
      console.log("error", error);
    });

    device.on("disconnect", (conn) => {
      rejectCall(true);
      if (leadId) {
        setShowfeedBack(true);
      }
    });

    device.on("cancel", (conn) => {
      rejectCall();
      if (leadId) {
        setShowfeedBack(true);
      }
    });

    device.on("connect", (conn) => {
      setCallInfo({
        ...callInfo,
        callStatus: "connected",
      });
    });

    device.incoming(async (conn) => {
      setShowInCommingCall(true);
      navigate('/dialer')
      setCallInfo({
        ...callInfo,
        displayIncoming: true,
      });
      connection = conn;
      const leadID = conn.customParameters.get("lead");
      if (leadID) {
        var response = await get(`leads/get-call-by-id?id=${leadID}`);
        var result = await response.json();
        if (result?.data) {
          callerData = result?.data;
          setCallInfo({
            ...callInfo,
            currentCallData: result?.data,
          });
        } else {
          // errorAlert(result?.errors?.[0] || "Something went to wrong!");
        }
      }
    });
  };

  const disconnectCall = () => {
    callerData = "";
    if ((window?.Twilio?.Device || leadId) && !showInCommingCall) {
      setShowfeedBack(true);
    }
    setShowInCommingCall(false);
    window?.Twilio?.Device?.disconnectAll();
    if (device) {
      device?.disconnectAll();
      if (connection) {
        connection?.ignore();
      }
    }
  };

  const startTimeRecording = (stopTimer = false) => {
    if (stopTimer) {
      clearInterval(timerInterval);
      timerData = 0;
      // exitCall();
      return false;
    } else {
      timerInterval = setInterval(time, 1000);
    }
  };

  const time = () => {
    timerData = timerData + 1;
    setTimer(new Date().getTime());
  };

  const rejectCall = (displaySummaryBlock = false) => {
    window?.Twilio?.Device?.disconnectAll();
    let newCallStatus = "idle";
    let newDisplayIncoming = false;

    if (timerInterval) {
      startTimeRecording(true);
      // startTimeRecording(false);
    }
    if (displaySummaryBlock) {
      newCallStatus = "disconnected";
      newDisplayIncoming = true;
    }
    setCallInfo({
      ...callInfo,
      displayIncoming: newDisplayIncoming,
      callStatus: newCallStatus,
    });
    disconnectCall();
  };

  const acceptCall = () => {
    isCall = true;
    if (connection) {
      connection.accept();
    }
    // console.log("aaaa Clicked....");
    startTimeRecording();
    // setCallInitiate(true);
    setTimeout(() => {
      setCallInitiate(true);
      setShowInCommingCall(false);
      console.log("callerData", callerData);
      callerData?.phone_number && setTargetNumber(callerData?.phone_number);
    });
  };

  const updateCallMuteStatus = (mute) => {
    setCallInfo({
      ...callInfo,
      callMuteStatus: mute,
    });
    connection?.mute(mute);
  };

  const handleLoadDialPade = () => {
    return (
      <div className="mt-3">
        <div className="keypad-dialer position-relative">
          <input
            value={keyTargetNumber}
            onChange={() => console.log("called")}
          />
          <BackspaceIcon
            onClick={() => {
              onBackspace();
            }}
            className="cursor-pointer h-8 w-8 absolute text-gray-400 top-6 right-10"
          />
        </div>

        <div className="dialer grid grid-cols-3	">
          <div
            onClick={() => {
              keyDialNumber("1");
            }}
          >
            1 <span>ABC</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("2");
            }}
          >
            2 <span>DEF</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("3");
            }}
          >
            3 <span>GHI</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("4");
            }}
          >
            4 <span>JKL</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("5");
            }}
          >
            5 <span>MNO</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("6");
            }}
          >
            6 <span>PQR</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("7");
            }}
          >
            7 <span>STU</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("8");
            }}
          >
            8 <span>VWX</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("9");
            }}
          >
            9 <span>YZ</span>
          </div>
          <div
            onClick={() => {
              keyDialNumber("*");
            }}
          >
            *
          </div>
          <div
            onClick={() => {
              keyDialNumber("0");
            }}
          >
            0
          </div>
          <div
            onClick={() => {
              keyDialNumber("#");
            }}
          >
            #
          </div>
        </div>
      </div>
    );
  };

  const callFeedback = async () => {
    if (leadId) {
      const payload = {
        lead_id: leadId,
        outcome: feedback?.outcome || "-",
        agent_feedback_text: feedback?.agent_feedback_text || "-",
      };
      let result = await postJson(
        "leads/call-feedback-dialog",
        JSON.stringify(payload)
      );
      result = await result.json();
      if (result?.errors?.length) {
        // errorAlert(result?.errors?.[0]);
      } else {
        handleCloseSummury();
      }
    } else {
      handleCloseSummury();
    }
  };

  const handleModalUI = () => {
    return (
      <>
        <Close className="close-icon" onClick={() => handleCloseSummury()} />
        <div className="text-white">
          <br></br>
          <br></br>
          <h3 className="pl-4 pr-4">Call Summary</h3>
          <div className="call-summary-modal pl-4 pr-4">
            <p> Call Outcome</p>
            <input
              value={feedback?.outcome}
              onChange={(e) =>
                setFeedback({
                  ...feedback,
                  outcome: e?.target?.value,
                })
              }
            />
            <p>Agent Notes</p>

            <textarea
              value={feedback?.agent_feedback_text}
              onChange={(e) =>
                setFeedback({
                  ...feedback,
                  agent_feedback_text: e?.target?.value,
                })
              }
            ></textarea>
          </div>
          <div className="pl-4 pr-4 d-flex">
            <button
              onClick={() => handleCloseSummury()}
              id="lead-view-on-web"
              className="mr-2 bg-white text-gray-700 btn-main hover:!bg-[#12c197] !text-gray-500 hover:!text-white hover:!border-[#12c197]"
            >
              Cancel
            </button>
            <button
              onClick={() => callFeedback()}
              id="lead-view-on-web"
              className="bg-white text-gray-700 btn-main hover:!bg-[#12c197] !text-gray-500 hover:!text-white hover:!border-[#12c197]"
            >
              Save
            </button>
          </div>
        </div>
      </>
    );
  };
  const handleModalAcceptUI = () => {
    return (
      <>
        <Close className="close-icon" onClick={() => handleCloseSummury()} />
        <div className="text-white">
          <br></br>
          <br></br>
          <h3 className="pl-4 pr-4">Incoming Call</h3>
          <div className="hor-row call-tool-container">
            <div className="user-image-container">
              <div className="user-image"></div>
              <div className="name-styles">
                {callerData?.customer_name &&
                callerData?.customer_name !== "undefined"
                  ? callerData?.customer_name
                  : " Unknown"}
              </div>
            </div>
          </div>
          <div className="pl-4 pr-4 d-flex">
            <button
              onClick={() => acceptCall()}
              id="lead-view-on-web"
              className="text-white mr-2 !bg-[#008000] text-gray-700 btn-main hover:!bg-[#12c197] !text-gray-500 hover:!text-white hover:!border-[#12c197]"
            >
              <PhoneIcon style={{ height: "28px" }} className="mr-2" /> Accept
              Call
            </button>
            <button
              onClick={exitCall}
              id="lead-view-on-web"
              className="!bg-[#ff0000] text-white !text-white-700 btn-main hover:!bg-[#12c197] !text-gray-500 hover:!text-white hover:!border-[#12c197]"
            >
              <PhoneIcon className="mr-2 rotate-icon" /> Reject Call
            </button>
          </div>
        </div>
      </>
    );
  };

  const handleCloseSummury = () => {
    setCallInfo({
      ...callInfo,
      displayIncoming: false,
      callStatus: "idle",
    });
    setFeedback({});
    setTimeout(() => {
      setShowfeedBack(false);
    }, 200);
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    exitCall();
  };



  return (
    <>
      {location?.pathname==='/dialer' ? 
      <div className="home pt-3">
        {callInitiate && (
          <>
            {disabledCall ? (
              <p id="error">{errorMessage}</p>
            ) : (
              <>
                <div className="call-dialer text-center">
                  <div className="text-left">
                    <h4>{"+" + targetNumber?.replace("+", "")}</h4>
                    <p>
                      {timerData
                        ? new Date(timerData * 1000)
                            ?.toISOString()
                            ?.substr(11, 8)
                        : "Calling..."}
                    </p>
                  </div>
                  <ul className="dialer-icon d-flex">
                    <li
                      onClick={() =>
                        updateCallMuteStatus(!callInfo?.callMuteStatus)
                      }
                      className={
                        callInfo?.callMuteStatus === true ? "active" : ""
                      }
                    >
                      <span>
                        <MicrophoneIcon />
                      </span>
                      <p>Mute</p>
                    </li>
                    <li
                      onClick={() => setKeypad(!keypad)}
                      className={keypad === true ? "active" : ""}
                    >
                      <span>
                        <DialPad />
                      </span>
                      <p>keypad</p>
                    </li>
                    <li
                      onClick={() => setSpeaker(!speaker)}
                      className={speaker === true ? "active" : ""}
                    >
                      <span>
                        <SpeakerWaveIcon />
                      </span>
                      <p>Speaker</p>
                    </li>
                  </ul>
                  {keypad && handleLoadDialPade()}
                  <button
                    onClick={exitCall}
                    disabled={inProgress}
                    id="dialer-call-btn"
                    className="inline-block text-white mx-auto exit-call"
                  >
                    <PhoneIcon />
                  </button>
                </div>
              </>
            )}
          </>
        )}
       
        {!callInitiate && (
          <>
            <p className="text-white float-right mx-5 my-1">
              Credits: ${credits?.toFixed(2)}
            </p>
            <div className="p-4 relative">
              {!callInitiate &&
                allowedCountries &&
                allowedCountries?.length > 0 && (
                  <PhoneInput
                    international
                    value={targetNumber}
                    onlyCountries={allowedCountries}
                    country={"us"}
                    enableSearch={true}
                    disabled={inProgress}
                    onChange={(e, data) => {
                      setCode("+" + data?.dialCode);
                      setTargetNumber(e);
                    }}
                  />
                )}

              {!callInitiate &&
                allowedCountries &&
                !allowedCountries?.length > 0 && (
                  <PhoneInput
                    international
                    value={targetNumber}
                    onlyCountries={allowedCountries}
                    country={"us"}
                    enableSearch={true}
                    disabled={inProgress}
                    onChange={(e, data) => {
                      setCode("+" + data?.dialCode);
                      setTargetNumber(e);
                    }}
                  />
                )}
              <BackspaceIcon
                onClick={() => {
                  onBackspace();
                }}
                className="cursor-pointer h-8 w-8 absolute text-gray-400 top-9 right-10"
              />
            </div>

            <div className="dialer grid grid-cols-3	">
              <div
                onClick={() => {
                  dialNumber("1");
                }}
              >
                1 <span>ABC</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("2");
                }}
              >
                2 <span>DEF</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("3");
                }}
              >
                3 <span>GHI</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("4");
                }}
              >
                4 <span>JKL</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("5");
                }}
              >
                5 <span>MNO</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("6");
                }}
              >
                6 <span>PQR</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("7");
                }}
              >
                7 <span>STU</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("8");
                }}
              >
                8 <span>VWX</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("9");
                }}
              >
                9 <span>YZ</span>
              </div>
              <div
                onClick={() => {
                  dialNumber("*");
                }}
              >
                *
              </div>
              <div
                onClick={() => {
                  dialNumber("0");
                }}
              >
                0
              </div>
              <div
                onClick={() => {
                  dialNumber("#");
                }}
              >
                #
              </div>
            </div>

            <div className="text-center -mt-2 mb-2">
              <button
                onClick={makeCall}
                disabled={inProgress}
                id="dialer-call-btn"
                className="inline-block text-white"
              >
                <PhoneIcon />
              </button>
            </div>

            <div className="dialer-via flex align-center px-4">
              <h4 className="text-white mr-3 mb-0"> via</h4>
              <div className="w-full">
                <SourceSelector
                  omitAll={true}
                  sources={sources}
                  selectedSource={selectedSource}
                  setSelectedSource={setSelectedSource}
                />
              </div>
            </div>
          </>
        )}
      </div>:''}
        {showfeedBack && (
          <FullscreenModal
            showTopNav={true}
            customeClass="full-screen"
            component={handleModalUI()}
          />
        )}
      {showInCommingCall && (
        <FullscreenModal
          showTopNav={true}
          customeClass="full-screen"
          component={handleModalAcceptUI()}
        />
      )}
      {renderDialer(token)}
    </>
  );
}
