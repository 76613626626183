import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { getCallerIds } from "../helpers/messages";
import {
    errorAlert,
    get,
    getCountryList,
    postJson,
    successAlert,
} from "../helpers/network";
import CountryData from "../helpers/data/countryCode.json";
import bookMark from "../images/bookmark-fill-svgrepo-com.svg";
import SourceSelector from "./SourceSelector";
import { getWidgetId } from "../helpers/globals";

export default function DownModel({ lead, scriptId }) {
    const [isLoading, setIsLoading] = useState(false);
    const [to, setTo] = useState(lead ? lead.phone_number : "");
    const [message, setMessage] = useState();
    const [callerIds, setCallerIds] = useState();
    const [selectedCallerId, setSelectedCallerId] = useState();
    const [sources, setSources] = useState([]);
    // const [code, setCode] = useState("+1");
    const [allowedCountries, setAllowedCountries] = useState([]);
    const [savedReplies, setSavedReplies] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const getAllLeads = async () => {
        let res = await get("user/quick-responses?target=Lead");
        res = await res.json();
        setSavedReplies(res?.data || []);
    };

    useEffect(() => {
        getWidgetCountries();
        getAllLeads();
        async function init() {
            var _callerIds = await getCallerIds();
            setCallerIds(_callerIds);
            if (_callerIds.length) {
                setSelectedCallerId(_callerIds[0]);
            }
        }

        init();
        getUserNumbers();
    }, []);

    const getUserNumbers = async () => {
        var _sources = await get("user/caller-id-with-names");
        _sources = await _sources.json();

        var numbers = [];
        for (var i = 0; i < _sources.data.length; i++) {
            if (i === 0) {
                setSelectedCallerId(
                    _sources.data[i]?.phone_number || _sources.data[i]?.number
                );
            }
            numbers.push(_sources.data[i]?.phone_number || _sources.data[i]?.number);
        }
        setSources(numbers);
    };

    const sendmessage = async () => {
        setIsLoading(true);

        var payload = {
            from: selectedCallerId,
            to: to,
            message: message,
            //  lead_id: lead ? lead.id : null,
        };

        if (lead) {
            var result = await postJson("user/send-sms", JSON.stringify(payload));
            result = await result.json();
        } else {
            var result = await postJson(
                "conversation",
                JSON.stringify({
                    friendly_name: `+${to}`,
                    number: `+${to}`,
                    script_id: scriptId,
                    source: selectedCallerId,
                    body: message,
                    message: message,
                })
                // JSON.stringify({
                //   friendly_name: to,
                //   number: to,
                //   script_id: scriptId,
                //   source: selectedCallerId,
                //   body: message,
                //   message: message,
                // })
            );

            result = await result.json();
        }

        if (result.errors && result.errors.length > 0) {
            errorAlert(result.errors[0]);
        } else {
            successAlert("Message has been sent.");
            setMessage("");
        }

        setIsLoading(false);
    };

    const getWidgetCountries = async () => {
        const w_id = await getWidgetId();
        var _sources = await getCountryList(
            `widget/get-widget-countries?widget_id=${w_id}`
        );
        _sources = await _sources.json();
        resetWidgetCountries(_sources?.data?.allowed_countries);
    };

    const resetWidgetCountries = (countries) => {
        const allCountry = CountryData;
        const allowed = allCountry
            .filter((item) => {
                if (countries?.includes(item.name)) {
                    return item;
                }
            })
            .map((item) => item.code.toLowerCase());
        setAllowedCountries(allowed);
    };

    const handleClick = () => {
        setIsSaved(true);
    };

    const handleMsgClick = (item) => {
        setIsSaved(false);
        setMessage(item?.description);
    };

    return (
        <>
            <h6 className="text-lg">
                Send a message {lead && <span>(#{lead ? lead.id : ""})</span>}
            </h6>
         
          
        </>
    );
}
