import { useEffect, useState } from "react";
import { get, postJson } from "../helpers/network";

export function TransferLead({ lead, refresh }) {
  const [userId, setUserId] = useState();
  const [assignList, setAssignList] = useState([]);
  const [membersTeam, setMembersTeam] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    getTeamMembers();
    // fetchTeams();
  }, []);

  useEffect(() => {
    processList();
  }, [membersTeam, teamMembers]);

  const getTeamMembers = async () => {
    var members = await get("team/members");
    members = await members.json();
    setAdmin([members.data.admin]);
    setTeamMembers(members.data.members);
  };

  // const fetchTeams = async () => {
  //   var members = await get("team/teams?limit=20000&offset=0");
  //   members = await members.json();
  //   setMembersTeam(members.data || []);
  // };

  const processList = () => {
    const allList = [...teamMembers, ...membersTeam, ...admin];
    const processList = allList.map((item) => {
      return {
        key: item.first_name?.length ? item.id : item.id + "-team",
        value: item.id,
        text: item.first_name?.length ? item.first_name : item.name + " team",
      };
    });
    setAssignList(processList || []);
  };

  const transfer = async (e) => {
    e.target.disabled = true;

    await postJson(
      `leads/set-owner`,
      JSON.stringify({
        lead_id: lead.id,
        user_id: userId,
      })
    );
    refresh();
  };

  const handleUpdateLead = (e) => {
    setUserId(e?.target?.value);
  };

  return (
    <>
      <div>
        <h6>Change Lead Owner</h6>
        <select
          onChange={handleUpdateLead}
          className="p-1 w-full mb-4 text-gray-900"
          id="lead_owner"
        >
          <option value="">Unassigned</option>
          {assignList &&
            assignList.map((member) => (
              <option key={member.value} value={member.value}>
                {member?.text}
              </option>
            ))}
        </select>
        <div className="mt-3 mb-3">
          <button onClick={transfer} id="transfer-lead" className="btn-main">
            Transfer
          </button>
        </div>
      </div>
    </>
  );
}
