import { get } from "./network";

export async function getWidget() {
  var widgets = await get("widget/widgets");
  widgets = await widgets.json();

  window.global_script = widgets?.data?.[0];
  return widgets?.data?.[0];
}

export async function getScritId() {
  if (window?.global_script && window?.global_script?.script_id) {
    return window.global_script.script_id;
  } else {
    var widget = await getWidget();
    return widget?.script_id;
  }
}

export async function getWidgetId() {
  if (window.global_script && window.global_script.id) {
    return window?.global_script.id;
  } else {
    var widget = await getWidget();
    return widget?.id;
  }
}

export function deploymentType() {
  const type = process.env?.NODE_ENV;
  return type;
}
