import Parser from "html-react-parser";
import moment from "moment";
import StarRatings from "react-star-ratings";

export default function LeadTabAbout({ lead, leadInfo }) {
  const addUrlProtocol = (_url) => {
    if (_url.indexOf("http://") == 0 || _url.indexOf("https://") == 0) {
      return _url;
    }
    return "https://" + _url;
  };

  const getUrlHost = (_url) => {
    try {
      return new URL(_url).host;
    } catch (e) {
      return _url;
    }
  };

  return (
    <>
       <div className="c-s-form">
        <p className="text-white">Call Data</p>
        <div className="item lead-info">
          <label htmlFor="">Lead Id:</label>
          <p id="id" className="lead-info">
            {lead?.id}
          </p>
        </div>
        {/* <div className="item lead-info">
          <label htmlFor="">IP:</label>
          <p id="ip" className="lead-info">
            {lead?.ip_address}
          </p>
        </div> */}
       {leadInfo?.lead?.type!=="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Answered by:</label>
          <p id="ip" className="lead-info">
            {lead?.agent}
          </p>
        </div>
}
        <div className="item lead-info">
          <label htmlFor="">Caller ID:</label>
          <p id="ip" className="lead-info">
            {lead?.phone_number}
          </p>
        </div>
        {/* <div className="item lead-info">
          <label htmlFor="">Timezone:</label>
          <p id="ip" className="lead-info">
            {"UTC"}
          </p>
        </div> */}
        {leadInfo?.lead?.type!=="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Call outcome:</label>
          <p id="ip" className="lead-info">
            {leadInfo?.lead?.outcome}
          </p>
        </div>
}
        <div className="item lead-info">
          <label htmlFor="">Call type:</label>
          <p id="ip" className="lead-info">
            {leadInfo?.lead?.type}
          </p>
        </div>
        {/* <div className="item lead-info">
          <label htmlFor="">Duration:</label>
          <p id="ip" className="lead-info">
            {leadInfo?.lead?.duration}
          </p>
        </div> */}
        <div className="item lead-info">
          <label htmlFor="">Time:</label>
          <p id="createdAtTime" className="lead-info">
          {moment.utc(lead.created_at).local().toLocaleString()/* + moment.tz.guess() */}
          </p>
        </div>
        {leadInfo?.lead?.type==="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Meeting Time:</label>
          <p id="createdAtTime" className="lead-info">
            {moment.utc(lead.request_call_time).local().toLocaleString()  /* + moment.tz.guess() */}
          </p>
        </div>}
        {/* <div className="item lead-info">
          <label htmlFor="">Time:</label>
          <p id="createdAtTime" className="lead-info">
            {new Date(lead.created_at).toLocaleString()}
          </p>
        </div>
        {leadInfo?.lead?.type==="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Meeting Time:</label>
          <p id="createdAtTime" className="lead-info">
            {new Date(lead.request_call_time).toLocaleString()}
          </p>
        </div>} */}
        {/* <div className="item lead-info">
          <label htmlFor="">Last interaction:</label>
          <p id="last_interaction" className="lead-info">
            {"" +
              (lead.trackingData ? lead.trackingData.last_interaction : "-")}
          </p>
        </div> */}
        {/* <div className="item lead-info">
          <label htmlFor="">Time on site:</label>
          <p id="time_on_site" className="lead-info">
            {"" + (lead.trackingData ? lead.trackingData.time_on_site : "-")}
          </p>
        </div> */}
        {/* <div className="item lead-info">
          <label htmlFor="">Call Type:</label>
          <p id="call_type" className="lead-info">
            {lead?.type}
          </p>
        </div> */}
        <div className="item lead-info">
          <label htmlFor="">Call Status:</label>
          <p id="status" className="lead-info">
            {lead?.final_status}
          </p>
        </div>
        {leadInfo?.lead?.type==="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Scheduled Call Status:</label>
          <p id="status" className="lead-info">
            {lead?.schedule_call_status}
          </p>
        </div>
}
        {leadInfo?.lead?.type!=="SCHEDULE_CALL" &&
        <div className="item lead-info">
          <label htmlFor="">Duration:</label>
          <p id="duration" className="lead-info">
            {lead?.duration ? lead?.duration : "O Sec"}
          </p>
        </div>
}
        <div className="item lead-info">
          <label htmlFor="">Recording:</label>
          <p id="recording_url" className="lead-info">
            {lead.recording_url
              ? Parser(
                  `<a target="_blank" href="${lead.recording_url}">Listen</a>`
                )
              : "Not available"}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Team:</label>
          <p id="team" className="lead-info">
            {lead.team ? lead.team : "-"}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Handled by:</label>
          <p id="handled_by" className="lead-info">
            {lead.owner ? lead.owner : "-"}
          </p>
        </div>
        {/* <div className="item lead-info">
          <label htmlFor="">Landing page:</label>
          <p id="landing_page" className="lead-info">
            {lead.first_source
              ? Parser(
                  `<a target="_blank" href="${addUrlProtocol(
                    lead.first_source
                  )}">${getUrlHost(lead.first_source)}</a>`
                )
              : "-"}
          </p>
        </div> */}
        <div className="item lead-info">
          <label htmlFor="">Source:</label>
          <p id="source" className="lead-info">
            {lead.referral
              ? Parser(
                  `<a target="_blank" href="${addUrlProtocol(
                    lead.referral
                  )}">${getUrlHost(lead.referral)}</a>`
                )
              : "-"}
          </p>
        </div>
      </div>
      <hr className="hr-style"></hr>
      <div className="c-s-form">
        <p className="text-white">Lead Form</p>
        <div className="item lead-info">
          <label htmlFor="">Name:</label>
          <p id="customer_name" className="lead-info">
            {lead.customer_name}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Lead type:</label>
          <p id="customer_name" className="lead-info">
            {lead?.type}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Company:</label>
          <p id="customer_name" className="lead-info">
            {leadInfo?.lead?.company_name}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Lead Source:</label>
          <p id="customer_name" className="lead-info">
            {lead?.source
              ? Parser(
                  `<a target="_blank" href="${addUrlProtocol(
                    lead?.source
                  )}">${getUrlHost(lead?.source)}</a>`
                )
              : "-"}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Email:</label>
          <p id="customer_email" className="lead-info">
            {lead.email ? lead.email : "-"}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Message:</label>
          <p id="customer_message" className="lead-info">
            {lead.widget_message ? lead.widget_message : "-"}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Referral:</label>
          <p id="customer_referral" className="lead-info">
            {lead.referral
              ? Parser(
                  `<a target="_blank" href="${addUrlProtocol(
                    lead.referral
                  )}">${getUrlHost(lead.referral)}</a>`
                )
              : "-"}
          </p>
        </div>
      </div>
   
   
      <hr className="hr-style"></hr>
      <div className="c-s-form">
        <p className="text-white">UTM Info</p>
        <div className="item lead-info">
          <label htmlFor="">Campaign:</label>
          <p id="id" className="lead-info">
            {leadInfo?.lead?.customer_utm &&
              leadInfo?.lead?.customer_utm?.utm_campaign}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Content:</label>
          <p id="ip" className="lead-info">
            {leadInfo?.lead?.customer_utm &&
              leadInfo?.lead?.customer_utm?.utm_content}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Medium:</label>
          <p id="createdAtTime" className="lead-info">
            {leadInfo?.lead?.customer_utm &&
              leadInfo?.lead?.customer_utm?.utm_medium}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Source:</label>
          <p id="last_interaction" className="lead-info">
            {leadInfo?.lead?.customer_utm &&
              leadInfo?.lead?.customer_utm?.utm_source}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Term:</label>
          <p id="time_on_site" className="lead-info">
            {leadInfo?.lead?.customer_utm &&
              leadInfo?.lead?.customer_utm?.utm_term}
          </p>
        </div>
      </div>
      <hr className="hr-style"></hr>
      <div className="c-s-form">
        <p className="text-white">Interactions</p>
        <div className="item lead-info">
          <label htmlFor="">IP:</label>
          <p id="id" className="lead-info">
            {leadInfo?.lead?.ip_address}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Country:</label>
          <p id="ip" className="lead-info">
            {leadInfo?.lead?.country}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Last activity:</label>
          <p id="createdAtTime" className="lead-info">
            {leadInfo?.lead?.trackingData &&
              leadInfo?.lead?.trackingData?.last_interaction}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Landing page:</label>
          <p id="last_interaction" className="lead-info">
            {lead?.first_source}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Referral:</label>
          <p id="time_on_site" className="lead-info">
            {leadInfo?.lead?.referral}
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Time on site:</label>
          <p id="time_on_site" className="lead-info">
            {leadInfo?.lead?.trackingData &&
              leadInfo?.lead?.trackingData?.time_on_site}
          </p>
        </div>
      </div>
      <hr className="hr-style"></hr>
      <div className="c-s-form">
        <p className="text-white">Agent Feedback:</p>
        <div className="item lead-info">
          <label htmlFor="">Agent Rating:</label>
          <p id="id" className="lead-info">
            <StarRatings
              rating={
                !lead?.agent_feedback_rate ? 0 : lead?.agent_feedback_rate
              }
              starRatedColor="#f78030"
              numberOfStars={5}
              name="rating"
            />
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Feedback:</label>
          <p id="ip" className="lead-info">
            {lead?.agent_feedback_text}
          </p>
        </div>
      </div>
      <hr className="hr-style"></hr>
      <div className="c-s-form">
        <p className="text-white">After Call Lead Feedback:</p>
        <div className="item lead-info">
          <label htmlFor="">Lead Rating:</label>
          <p id="id" className="lead-info">
            <StarRatings
              rating={
                !lead?.customer_feedback_rate ? 0 : lead?.customer_feedback_rate
              }
              starRatedColor="#f78030"
              numberOfStars={5}
              name="rating"
            />
          </p>
        </div>
        <div className="item lead-info">
          <label htmlFor="">Feedback:</label>
          <p id="ip" className="lead-info">
            {lead?.customer_feedback_text}
          </p>
        </div>
      </div>
    </>
  );
}
